import CryptoJS from 'crypto-js';
import axios from 'axios';
import { useNavigate} from "react-router-dom";


const generateRandomString = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

//Creamos una variable Axios aparte donde no tenga el token en la cabecera
const loginKeyCloack = axios.create({
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
});

const cleanBase64 = cadena => (
    //Reemplazamos caracteres extraños que no lee JWT
    cadena.replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=/g, '')
    )
    
//Identificador del cliente para keycloak (oauth2)
// const client_id = "spa-safire-services";//process.env.REACT_APP_CLIENT_ID;
const client_id = process.env.REACT_APP_CLIENT_ID;
//Secreto del cliente para la autenticación (oauth2)
// const client_secret = "y7dEyAW5bUbi6yz2Vl9g9cnGtdKyZDrN";//process.env.REACT_APP_CLIENT_SECRET;
export const GetAuthorizationCode = () =>{
    //GetAuthorizationCode() Pide el cupón, generlo los code verifier y challenge y construye la URL de redirección.
    //generate a code verifier
    let code = generateRandomString(43);
    //Pasamos el número a string base64
    let code_verifier = cleanBase64(btoa(code).toString('base64'));
    //get code challenge
    let code_challenge = cleanBase64(CryptoJS.SHA256(code_verifier).toString(CryptoJS.enc.Base64));
    //Para qué funcionalidad queremos solicitar la autorización, en este caso para clientes con APPSims activo
    const scope = 'openid';
    //Método de encriptación que hemos utilizado en CryptoJS, en este caso SHA256
    const code_challenge_method = 'S256'
    ////Guardar code_verifier y estado
    localStorage.setItem('code_verifier', code_verifier);
    //Código de la aplicación autorizada para poder logearse
    const client_id = process.env.REACT_APP_CLIENT_ID;
    let url = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/visiotech/protocol/openid-connect/auth?response_type=code&client_id=${client_id}&redirect_uri=${process.env.REACT_APP_DNS_FRONTEND_URL}/auth&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}&scope=${scope}`;
    //Redireccionamos a la Web
    window.location.href =  url;
}

//coger de sesion o local el token y el refresh y pedirlo al endpoint para refrescarlo
export const refreshToken = async () =>{
    const refresh_token= localStorage.getItem("refresh_token");
    //Código de la aplicación autorizada para poder logearse
    const client_id = process.env.REACT_APP_CLIENT_ID;
    //Secreto de la aplicación para iniciar sesión
    const client_secret = process.env.REACT_APP_CLIENT_SECRET; 
    let body = `grant_type=refresh_token&client_id=${client_id}&client_secret=${client_secret}&refresh_token=${refresh_token}`;

    return fetchToken(body).then(result => {
        //Modificamos el access y el refresco que estaban caducados
        setToken(result.access_token, result.refresh_token);
    }).catch (async error => {
        GetAuthorizationCode();
        return Promise.reject(error);
    })
}

export const GetToken = (code) => {
    let navigate = useNavigate();
    const code_verifier = localStorage.getItem('code_verifier');
    //Código de la aplicación autorizada para poder logearse
    const client_id = process.env.REACT_APP_CLIENT_ID;
    //Secreto de la aplicación para iniciar sesión
    const client_secret = process.env.REACT_APP_CLIENT_SECRET;
    let body = `grant_type=authorization_code&client_id=${client_id}&code_verifier=${code_verifier}&client_secret=${client_secret}&code=${code}&redirect_uri=${process.env.REACT_APP_DNS_FRONTEND_URL}/auth`;
    fetchToken(body).then(result => {
        //gUARDAR el token en sesionstorage o localstorage
        setToken(result.access_token,result.refresh_token);
        navigate("/");
    }).catch (() => {
        //comprobar si no nos devuelve token, ver el tipo de error y redirigir
            console.log("Error en el GetToken")
    });

}

//Cierre de sesión
// export const LogOut = () => {
//     const client_id = "spa-safire-services";
//     const refresh_token= localStorage.getItem("refresh_token");
//     const client_secret = "UdRP1iqLcN5vi8xAtD4IkGISEzX2s7Oj"
//     let body = `client_id=${client_id}&client_secret=${client_secret}&refresh_token=${refresh_token}`;

//     //Borramos los datos de la sesión del localstorage
//     localStorage.clear();

//     //Llamamos al endpoint de cierre de sesión con el Axios original que ya incluye el token
//     axios.post(`${process.env.REACT_APP_KEYCLOAK_URL}/realms/safire-services/protocol/openid-connect/logout`, body)
//         .then(function (response){
//             console.log("LOGOUT OK")
//         }).catch(function (error){
//         console.log(error);
//         // return Promise.reject(error)
//     });

// }

const fetchToken = (body) => {
        return loginKeyCloack.post(`${process.env.REACT_APP_KEYCLOAK_URL}/realms/visiotech/protocol/openid-connect/token`, body)
        .then(function (response) {
                        return response.data;
        })
        .catch(function (error) {
                        return Promise.reject(error);
        });
}

//Guardamos el token y refresh en local y actualizamos las cabeceras de las peticiones a la api
const setToken  = (token, refreshToken) => {
    localStorage.setItem("access_token",token);
    localStorage.setItem("refresh_token",refreshToken);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
