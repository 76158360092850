import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import "../css/InfoConfiguration.css";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import * as api from "../components/services/api";
import ConfirmDeleteModal from '../utils/confirmDeleteModal';
import {ipWaitingDevice} from "../utils/ConstUtils";
import { formatDate, getTime } from '../utils/CommonMethods';
import { Skeleton } from '@mui/material';
import InfoConfigurationSkeleton from './Skeletons/InfoConfigurationSkeleton';
import { useTranslation } from 'react-i18next';
import DeviceStates from '../components/commonUtils/DeviceStates';
import AlertAction from '../components/commonUtils/AlertAction';

/**
 * /infoConfig
 * Pagina de informacion del dispositivo.
 * 
 * @returns {Container}
 */
const InfoConfiguration = () => {
    const {t} = useTranslation('safirednstranslation');
    let navigate = useNavigate();

    // Se obtiene el id del dominio por parametro
    let { domainId= ""} = useParams();
    
    const [domain, setDomain] = useState({})

    // Mostrar la conexion y ultimo cambio de ip del dispositivo.
    const [device, setDevice] = useState([]);

    // Mostrar la creacion y ultima edicion del dominio.
    const [domainInfo, setDomainInfo] = useState([]);

    // Mostrar informacion relevante a la configuracion del dispositivo.
    const [configuration, setConfiguration] = useState([]);

    // Mostrar u ocultar la contraseña.
    const [hidePassword, setHidePassword] = useState(true);

    // Habilitar modal de borrado si el usuario desea borrar ese dominio.
    const [showDeleteModal,setShowDeleteModal]=useState(false);

    // Almacenar nombre de dominio a borrar para enviarselo al modal.
    const [domainToDelete,setDomainToDelete]=useState();

    // Para mostrar los skeletons hasta que carguen los datos.
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        // Redireccion a la pantalla principal si no viene id del dominio
        if(domainId === "") { navigate("/homeDNS")}

        api.getDomain(domainId).then(result => {
            setDomain(result)
            fillTables(result)
        }).catch(() => {
            navigate("/homeDNS");
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Comprobar si se ha actualizado despues la fecha de ipv6 o ipv4
    const getLastDeviceUpdate = (domain) => {
        let dateIpv4 = formatDate(domain.resourcerecords.ipv4?.lastDeviceUpdate);
        let dateIpv6 = formatDate(domain.resourcerecords.ipv6?.lastDeviceUpdate);
        
        if(dateIpv4 === '-' && dateIpv6 === '-') {
            return '-';
        } else if(dateIpv4 === '-') {
            return dateIpv6;
        } else if(dateIpv6 === '-') {
            return dateIpv4;
        }

        return getTime(dateIpv4) >= getTime(dateIpv6) ? dateIpv4 : dateIpv6
    }
    
    // Rellenar datos de las diferentes tablas de la pagina.
    const fillTables = (domain) => {
        
        //Eliminamos la cabecera (si la tiene) para sacar el nombre del servidor.
        let proxyUrl = process.env.REACT_APP_PROXY_URL;
        if (proxyUrl) {
            let delimiterIndex = proxyUrl.indexOf("://");
            if (delimiterIndex !== -1) {
                proxyUrl = proxyUrl.substring(delimiterIndex + "://".length);
            }
        } else {
            proxyUrl = "ERROR: Missing environment variable."
        }

        setDevice([
            {label: t('lastConnection'), value: formatDate(domain.proxyInfo?.lastSeen)},
            {label: t('lastChangeIP'), value: getLastDeviceUpdate(domain)}
        ]);

        setDomainInfo([
            {label: t('creation'), value: formatDate(domain.createdAt)},
            {label: t('lastUpdate'), value: formatDate(domain.updatedAt)}
        ]);

        setConfiguration([
            {label: t('serverType'), value: "DynDNS"},
            {label: t("serverName"), value: proxyUrl},
            {label: t('serverPort'), value: "80"},
            {label: t('domainName'), value: domain.name},
            {label: t('user'), value: domain.username},
            {label: t('password'), value: domain.password, type: "password"},
        ]);

        setIsLoading(false);
    }

    const deleteDomain = (domain) => {
        setDomainToDelete(domain);
        setShowDeleteModal(true);
    }

    function closeDeleteModal() {
        setShowDeleteModal(false);
    }

    const ipSubtitle = () => {
        const ipv4Value = domain.resourcerecords?.ipv4?.value;
        const ipv6Value = domain.resourcerecords?.ipv6?.value;
        const ipv4LastDeviceUpdate = domain.resourcerecords?.ipv4?.lastDeviceUpdate;
        const ipv6LastDeviceUpdate = domain.resourcerecords?.ipv6?.lastDeviceUpdate;

        // en el caso de que no exista ninguna, se pone un -
        if (!ipv4Value && !ipv6Value) {
            return '';
        }

        // si solo existe una, ponemos la que exista
        if (ipv4Value && !ipv6Value) {
            if (ipv4Value === ipWaitingDevice) {
                return '';
            }
            return ipv4Value;
        }

        if (ipv6Value && !ipv4Value) {
            return ipv6Value;
        }

        // en caso de que existan las dos, entonces comparamos las fechas
        // si la v6 no se ha actualizado nunca, entonces retornamos la ip v4
        // este caso también cubre cuando ninguna se ha actualizado
        if (!ipv6LastDeviceUpdate) {
            return ipv4Value;
        }

        // si la v4 no se ha actualizado nunca, entonces retornamos la ip v6
        if (!ipv4LastDeviceUpdate) {
            return ipv6Value;
        }

        // si las dos se han actualizado, cogemos la última
        if (ipv4LastDeviceUpdate >= ipv6LastDeviceUpdate) {
            return ipv4Value;
        } else {
            return ipv6Value;
        }
    }

  return (
    <Container fluid>
        <div className="px-5" style={{maxWidth:"1200px", margin: "auto"}}>
            {/* Alerta */}
            <Row style={{margin:'30px auto'}}>
                <AlertAction />
            </Row>
            {/* Nombre de dominio y estado de dispositivo */}
            <div className="title-container">
                <div style={{overflow:"hidden"}}>

                            {isLoading
                                ?
                                <Skeleton height={40} width={150} variant="text" className="col-md-8"/>
                                :
                                <div>
                                    <h4 className='text-primary title-page d-inline-block me-4 mb-0'>{domain.name}</h4>
                                </div>
                            }


                            {isLoading ?
                                <Skeleton height={30} width={100} variant="text" className="col-md-2"/>
                                :
                                <div className='ip-subtitle'>
                                    {ipSubtitle()}
                                </div>
                            }

                </div>

                    {isLoading
                    ?
                        <Skeleton height={60} width="20%" variant="text"/>
                    :
                        <div className="vertical-flex">
                            <DeviceStates domain={domain}/>
                        </div>
                    }

            </div>
            {/* Dispositivo y dominio */}
            <Row className="my-4" style={{rowGap: "15px"}}>
                <Col  className="vertical-flex">
                    <div className="rounded-border"  style={{height:'100%'}}>
                        <h6 className='text-primary pb-2'><DnsOutlinedIcon/> {t('device')}</h6>
                        {isLoading 
                        ?
                            <InfoConfigurationSkeleton number={2}/>
                        :
                        	device.map((info, index) => (
                            <Row className="mb-2 px-4" 
                                key={index}
                            >
                                <Col className="label-infoconfig">{info.label}</Col>
                                <Col className="text-secondary">{info.value}</Col>
                            </Row>
                        ))}
                    </div>
                </Col>
                <Col  className="vertical-flex">
                    <div className="rounded-border" style={{height:'100%'}}>
                        <h6 className='text-primary pb-2'><LanguageOutlinedIcon/> {t('domain')}</h6>
                        {isLoading 
                        ?
                            <InfoConfigurationSkeleton number={2}/>
                        :
                        	domainInfo.map((info, index) => (
                            <Row className="mb-2 px-4" 
                                key={index}
                            >
                                <Col className="label-infoconfig">{info.label}</Col>
                                <Col className="text-secondary">{info.value}</Col>
                            </Row>
                        ))}
                    </div>
                </Col>
            </Row>
            {/* Configuración del dispositivo */}
            <Row>
                <Col>
                    <div className="rounded-border vertical-flex">
                        <div className='text-primary pb-2'>
                            <h6><SettingsOutlinedIcon/> {t('deviceConfiguration')}</h6>
                        </div>
                        <Row className="my-2 px-4">
                            <Col>
                            {isLoading
                            ?
                                <InfoConfigurationSkeleton number={5}/>
                            :
                                configuration.map((config, index) => (
                                    <Row key={index} className="mb-2">
                                        <Col className="col-md-4 label-infoconfig">{config.label}</Col>
                                        <Col className="text-secondary col-md-8">
                                            <span style={{minWidth: '100px', display:'inline-block'}}>{config.type==='password' && hidePassword ? '*******' : config.value}</span>
                                            {config.type==='password' &&
                                                <span role="button" onClick={() => setHidePassword(!hidePassword)}>
                                                    {hidePassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                                                </span>
                                            }
                                        </Col>
                                    </Row>
                                ))
                            }
                            </Col>
                            <Col className='col-md-4'>
                                <img alt="Error" src="/img/infoconfig.svg" style={{marginBottom: "25px", maxWidth: "100%"}}/>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
        <div className='alignCenter'>
            {isLoading
            ?
            <div className="containerDomain justify-content-center">
                <Skeleton height={40} variant="text" className="col-md-1 mx-1"/>
                <Skeleton height={40} variant="text" className="col-md-1 mx-1"/>
                <Skeleton height={40} variant="text" className="col-md-1 mx-1"/>
            </div>
            :
            <div className='footer-buttons'>
                <span role="button" className="text-primary me-md-4" onClick={() => deleteDomain(domain)}>{t('delete')}</span>
                <Button className="button" onClick={() => navigate("/homeDNS")} variant="outline-primary">{t('goBack')}</Button>
                <Button className="button" onClick={() => navigate('/domain/' + domain.name)}>{t('edit')}</Button>
            </div>
            }
        </div>
        <ConfirmDeleteModal show={showDeleteModal} close={closeDeleteModal}  domain={domainToDelete}/>
    </Container>
  )
}

export default InfoConfiguration
