import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import { useState } from "react";
import {
  formatDateDDMMAAAA,
  getLastYear,
  getTime,
} from "../../utils/CommonMethods";
import { useEffect } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/**
 * Este componente se utiliza para conocer el estado de un dispositivo.
 *
 * @param {*} props
 * @returns {<>}
 */
const DeviceStates = (props) => {
  const { t } = useTranslation("safirednstranslation");

  const { domain, proxyStatus, onlyIcon = false, iconStyle = "fs-3" } = props;

  // Mostrar cual es el estado del dispositivo, se recoge del endpoint.
  const [stateDevice, setStateDevice] = useState({
    code: "waiting",
    color: "blue",
    icon: <AccessTimeOutlined className={iconStyle} />,
    label: t("waitingConnection"),
  });

  // Array con los diferentes estados que puede tener un dispositivo.
  const states = [
    {
      code: "good",
      color: "success",
      icon: <img alt="connected" src="/img/ds_icons/good.svg" className={onlyIcon ? "icon-state" : ""} />,
      label: t("connected"),
    },
    {
      code: "nochg",
      color: "success",
      icon: <img alt="connected" src="/img/ds_icons/good.svg" className={onlyIcon ? "icon-state" : ""} />,
      label: t("connected"),
    },
    {
      code: "badauth",
      color: "warning",
      icon: (
        <img alt="badAuth" src="/img/ds_icons/badauth.svg" className={onlyIcon ? "icon-state" : ""} />
      ),
      label: t("badAuth"),
    },
    {
      code: "badagent",
      color: "warning",
      icon: (
        <img alt="deviceError" src="/img/ds_icons/badagent.svg" className={onlyIcon ? "icon-state" : ""} />
      ),
      label: t("errorDevice"),
    },
    {
      code: "dnserr",
      color: "warning",
      icon: <img alt="errorServer" src="/img/ds_icons/dnserr.svg" className={onlyIcon ? "icon-state" : ""} />,
      label: t("errorServer"),
    },
    {
      code: "waiting",
      color: "blue",
      icon: <img alt="waiting" src="/img/ds_icons/waiting.svg" className={onlyIcon ? "icon-state" : ""} />,
      label: t("waitingConnection"),
    },
    {
      code: "outdated",
      color: "danger",
      icon: <img alt="outdated" src="/img/ds_icons/outdated.svg" className={onlyIcon ? "icon-state" : ""} />,
      label: t("outdated"),
      value: t("outdatedYearAgo"),
    },
    {
      code: "abuse",
      // color: "warning",
      // icon: <img alt="abuse" src="/img/ds_icons/abuse.svg" className={onlyIcon ? "icon-state" : ""}/>,
      // label: t("temporarilyRestricted"),
      // value: t("temporarilyRestrictedInfo"),
      color: "success",
      icon: <img alt="connected" src="/img/ds_icons/good.svg" className={onlyIcon ? "icon-state" : ""} />,
      label: t("connected"),
    },
  ];

  // Se comprueba el estado del dispositivo y se comprueba si se ha actualizado en menos de un año.
  useEffect(() => {
    let res = states.find((state) => state.code === "waiting");

    if (
      domain?.hasOwnProperty("proxyInfo") &&
      Object.keys(domain.proxyInfo).length > 0
    ) {
      if (states.some((state) => state.code === domain.proxyInfo.status)) {
        res = states.find((state) => state.code === domain.proxyInfo.status);
      }
      res = checkIfUpdatedYearAgo(domain, res);
    } else if (proxyStatus !== undefined && proxyStatus !== null) {
      res = states.find((state) => state.code === proxyStatus);
    }

    setStateDevice(res);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Se comprueba que se ha actualizado el dominio en menos de un año.
  const checkIfUpdatedYearAgo = (domain, res) => {
    const date = getTime(formatDateDDMMAAAA(domain.proxyInfo.lastSeen));
    const yearAgo = getTime(getLastYear());

    if (date < yearAgo) {
      return states.find((state) => state.code === "outdated");
    }
    return res;
  };

  return (
    <>
      {
        // Si solo queremos mostrar el icono de los estados del dispositivo.
        onlyIcon ? (
          <div className={`text-${stateDevice?.color} text-center`}>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>{stateDevice?.label}</Tooltip>}
            >
              <span>{stateDevice?.icon}</span>
            </OverlayTrigger>
          </div>
        ) : (
          <>
              <div
              className={`text-${stateDevice?.color} bg-${stateDevice?.color} bg-opacity-10 rounded p-2`}
              style={{minWidth:"250px"}}
            >
              <Row style={{padding: "10px 0px"}}>
                <Col xs={3} md={3}>
                  {/* Columna izquierda para el icono */}
                  <div>
                    <span className="ps-2">{stateDevice?.icon}</span>
                  </div>
                </Col>
                <Col xs={9} md={9}>
                  {/* Columna derecha para el contenido de dos columnas */}
                  <Row>
                    <span
                      className="overflow-hidden"
                      style={{ fontWeight: "bold" }}
                    >
                      {stateDevice?.label}
                    </span>
                  </Row>
                  {stateDevice?.value && (
                    <Row style={{ color: "grey" }}>
                      <span style={{ fontSize: "0.8rem" }}>
                        {stateDevice?.value}
                      </span>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
          </>
        )
      }
    </>
  );
};

export default DeviceStates;
