import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../css/Configuration.css";
import ZoneModal from "../components/commonUtils/modals/ZoneModal";
import GroupModal from "../components/commonUtils/modals/GroupModal";
import SubdomainsModal from "../components/zonesGroupsTable/subdomainsModal";
import Table from "../components/zonesGroupsTable/table";
import * as api from "../components/services/api";
import ErrorDialog from "../components/services/errorDialog";
import ErrorContext from "../components/services/Context/ErrorContext";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../components/commonUtils/modals/DeleteModal";
import ActionModal from "../components/commonUtils/modals/ActionModal";
import ConfigurationSkeleton from "./Skeletons/ConfigurationSkeleton";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";

const config = require('../config/config');

/**
 * /config
 * Pagina de configuracion.
 * Se mostraran dos tablas con todos los grupos y zonas que esten asociados al usuario.
 * 
 * @returns {Container}
 */
const Configuration = () => {
    // const {register, handleSubmit, clearErrors, formState: {errors}, control, reset, setValue} = useForm();
    const { t } = useTranslation('safirednstranslation');

    // En caso de que el usuario quiera ver un grupo haciendo click o añadir un grupo nuevo, se mostrara modal de grupos.
    const [showGroupModal, setShowGroupModal] = useState(false);

    // En caso de que el usuario quiera ver una zona haciendo click o añadir una zona nueva, se mostrara modal de zona.
    const [showZoneModal, setShowZoneModal] = useState(false);

    // Se almacenan las zonas y la informacion de cada una tras llamar al endpoint de back.
    const [zones, setZones] = useState([]);

    // Se almacenan los grupos y la informacion de cada uno tras llamar al endpoint de back.
    const [groups, setGroups] = useState([]);

    // Formato para pasarlo directamente a la tabla de material -> Grupo + numero de dominios del grupo
    const [dataTableGroups, setDataTableGroups] = useState([]);

    // Formato para pasarlo directamente a la tabla de material -> Zona + numero de subdominios del grupo
    const [dataTableZones, setDataTableZones] = useState([]);

    // Array con los grupos + los dominios por cada grupo
    const [groupDomainsModalData, setGroupDomainsModalData] = useState([]);

    // Array con las zonas + los subdominios por cada zona
    const [zoneSubdomainsModalData, setZoneSubdomainsModalData] = useState([]);

    // Informacion de la fila seleccionada, ya sea grupo o zona.
    const [selectedRow, setSelectedRow] = useState("");

    // Habilitar modal de mostrar dominios asociados a un grupo o zona.
    const [showSubdomainModal, setShowSubdomainModal] = useState(false);

    // Para conocer en que tabla ha interactuado el usuario, si zona o grupo.
    const [tableType, setTableType] = useState();

    // Se almacena el id del grupo o zona para poder mandarlo al modal y actualizarlo.
    const [id, setId] = useState("");

    // Se almacena el nombre de la zona para poder enviarlo al modal. Se usara cuando no venga relleno del state.
    const [name, setName] = useState("");

    // Obtener del contexto para mostrar errores, en este caso cuando se alcance el numero maximo de zonas.
    const { setErrorMessage } = useContext(ErrorContext);

    // Obtener del contexto para mostrar errores, en este caso cuando se alcance el numero maximo de zonas.
    const { setShowErrorDialog } = useContext(ErrorContext);

    // Zonas obtenidas de llamar al endpoint, enviadas al modal de zonas para el selector.
    const [customZones, setCustomZones] = useState([]);

    /** 
     * Utilizado para eliminar una zona o un grupo
     * delete -> si se desea eliminar. 
     * showError -> si ha habido algun problema al intentar borrar 
     * confirmed -> borrado OK
     * */
    const [deleteArea, setDeleteArea] = useState({ delete: false, showError: false, confirmed: false });

    // En el caso de eliminar una zona, es necesario dejar de utilizarla.
    const [zoneTemp, setZoneTemp] = useState({ previousId: config.domains.defaultZoneId, previousZone: config.domains.defaultZone, actualId: config.domains.defaultZoneId, actualZone: config.domains.defaultZone });

    // Utilizado para comprobar cuando se han recuperado las zonas y mostrar skeleton.
    const [isLoadingZones, setIsLoadingZones] = useState(true);

    // Utilizado para comprobar cuando se han recuperado los grupos y mostrar skeleton.
    const [isLoadingGroups, setIsLoadingGroups] = useState(true);

    let navigate = useNavigate();

    // Se cargan los dominios, zonas y grupos.
    useEffect(() => {
        api.getGroups().then((result) => {
            setGroups(result);
            setIsLoadingGroups(false)
        });
        api.getZonesWithParents().then((result) => {
            setCustomZones(result)
            checkZones(result);
            setIsLoadingZones(false)
        });
    }, []);

    // Al obtener los grupos, rellenamos dataTable y modalData para mostrar los datos en tabla y modal.
    useEffect(() => {
        let dataTable = [];
        let modalData = [];

        if (groups !== null && Object.keys(groups).length !== 0) {
            groups.forEach(group => {
                let tableFormat = {};
                let modalFormat = {};
                tableFormat.id = group.id;
                tableFormat.name = group.name;
                modalFormat.name = group.name;
                modalFormat.domains = [];
                if(group.domains.length > 0) {
                    (group.domains).forEach(domain => {
                        modalFormat.domains.push(domain);
                    });
                }
                tableFormat.numberDomains = group.domains.length;
                dataTable.push(tableFormat);
                modalData.push(modalFormat);
            });
        }
        setDataTableGroups(dataTable);
        setGroupDomainsModalData(modalData);
    }, [groups]);

    // Al obtener las zonas, rellenamos dataTable y modalData para mostrar los datos en tabla y modal.
    useEffect(() => {
        let dataTable = [];
        let modalData = [];
        if (zones !== null && Object.keys(zones).length !== 0) {
            zones.forEach(zone => {
                let tableFormat = {};
                let modalFormat = {};
                tableFormat.id = zone.id;
                tableFormat.domain = zone.domain;
                modalFormat.domain = zone.domain;
                modalFormat.domains = [];
                if(zone.domains.length > 0) {
                    (zone.domains).forEach(domain => {
                        modalFormat.domains.push(domain);
                    });
                }
                tableFormat.numberSubdomains = zone.domains.length;
                dataTable.push(tableFormat);
                modalData.push(modalFormat);
            });       
        }
        setDataTableZones(dataTable);
        setZoneSubdomainsModalData(modalData);
    }, [zones]);

    // Actualiza los grupos despues de realizar una accion.
    const refreshGroups = () => {
        api.getGroups().then((result) => {
            setGroups(result);
        });
    }

    // Actualiza las zonas despues de realizar una accion.
    const refreshZones = () => {
        api.getZones().then((result) => {
            setZones(result);
        });
    }


    function openGroupModal() {
        setShowGroupModal(true);
    }

    function closeGroupModal() {
        setId("");
        setShowGroupModal(false);
    }

    function closeZoneModal() {
        setId("");
        setShowZoneModal(false);
    }

    function closeSubdomainModal() {
        setShowSubdomainModal(false);
    }

    function openZoneModal() {
        setShowZoneModal(true);
    }

    const closeDeleteModal = () => {
        setId("");
        setDeleteArea({ delete: false, confirmed: false, showError: false })
    }

    // Mostramos error con showError cuando se intente borrar una zona o un grupo con dominios asociados.
    const handleDelete = (row, table) => {
        var show = false
        if (table === "zone") {
            zones.forEach(zone => {
                if (zone.domain === row.domain) {
                    show = zone.domains !== '' && zone.domains > 0
                    setId(zone.name);
                    setName(row.domain);
                }
            })
        } else if (table === "group") {
            groups.forEach(group => {
                if (group.name === row.name) {
                    show = group.domains !== '' && group.domains > 0
                    setId(group.id);
                    setName(row.name);
                }
            })
        }
        setTableType(table)
        setDeleteArea({ delete: true, showError: show, confirmed: false })
        setSelectedRow(row);
    }

    // Abrir modal con la fila que corresponda dependiendo de donde haya hecho click el usuario.
    function openTableModal(d, table) {
        let data;
        if (table === "zone") {
            data = zoneSubdomainsModalData.slice();           
            data.forEach(x => {
                if (x.domain === d.domain) {
                    setSelectedRow(x);
                }
            });
        } else {
            data = groupDomainsModalData.slice();
            data.forEach(x => {
                if (x.name === d.name) {
                    setSelectedRow(x);
                }
            });
        }
        setTableType(table);
        setShowSubdomainModal(true);
    }

    function handleEdit(row, table) {
        if (table === "zone") {
            zones.forEach(zone => {
                if (zone.domain === row.domain) {
                    setId(zone.name);
                    setName(zone.domain)
                    openZoneModal();
                }
            });
        } else {
            groups.forEach(group => {
                if (group.name === row.name) {
                    setId(group.id);
                    setName(group.domain)
                    openGroupModal();
                }
            })
        }
        setTableType(table)
    }

    // Comprobar si el maximo de zonas es superado por el numero de zonas que tiene el usuario.
    function handleOpenZoneModal() {
        api.getUserInfo().then(result => {
            if (zones.length < result.quota.maxZones) {
                openZoneModal();
            } else {
                setErrorMessage(t('limitZones'));
                setShowErrorDialog(true);
            }
        });
    }

    /**
     * Comprobar si hay zonas diferentes a las zonas padre permitidas para ese entorno.
     * 
     * @param {*} zones 
     */
    const checkZones = (zones) => {
        const parentZones = config.domains.allowedParentZones;
        const parentZonesNames = parentZones.map(e => e.name);
        if (Object.keys(zones).length !== 0) {
            setZones(Object.values(zones.filter(zone => !parentZonesNames.includes(zone.name))));
        }
    }

    return (
        <Container fluid className="p-4" style={{ maxWidth: "1200px", margin: "auto" }}>
            <ErrorDialog />
            <h4 className="text-primary title-page ms-3">{t('configuration')}</h4>
            {showZoneModal && <ZoneModal
                closeModal={closeZoneModal}
                deleteArea={deleteArea}
                name={name}
                refreshZones={refreshZones}
                setDeleteArea={setDeleteArea}
                setZoneTemp={setZoneTemp}
                showZoneModal={showZoneModal}
                zoneId={id}
                zones={customZones}
                zoneTemp={zoneTemp}
            />}
            {showGroupModal &&
                <GroupModal
                    closeModal={closeGroupModal}
                    deleteArea={deleteArea}
                    groupId={id}
                    refreshGroups={refreshGroups}
                    setDeleteArea={setDeleteArea}
                    showGroupModal={showGroupModal}
                />}
            <DeleteModal
                addButton
                deleteArea={deleteArea}
                handleClose={closeDeleteModal}
                id={id}
                name={name}
                openTableModal={openTableModal}
                refreshGroups={refreshGroups}
                refreshZones={refreshZones}
                selectedRow={selectedRow}
                setDeleteArea={setDeleteArea}
                table={tableType}
            />
            <SubdomainsModal show={showSubdomainModal} type={tableType} close={closeSubdomainModal} data={selectedRow} refreshZones={refreshZones} refreshGroups={refreshGroups} />
            <Row>
                <Col md={6}>
                    <div className="d-flex align-items-baseline justify-content-between mb-3" style={{ minHeight: "45px" }}>
                        <h5 sm={4}>{t('zones')}</h5>
                        {Object.keys(zones).length !== 0 && <Button variant="primary" className="button_config" onClick={handleOpenZoneModal}>+ {t('addZone')}</Button>}
                    </div>
                    {
                        isLoadingZones
                            ?
                            <ConfigurationSkeleton />
                            :
                            Object.keys(zones).length !== 0
                                ?
                                <Table
                                    columns={
                                        [
                                            {
                                                title: t('domain'),
                                                field: "domain",
                                                render: row =>
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleEdit(row, "zone")}
                                                    >
                                                        {row.domain}
                                                    </div>
                                            },
                                            {
                                                title: t('subdomains'),
                                                field: "numberSubdomains",
                                                render: row =>
                                                (row.numberSubdomains === 0 ?
                                                    "-"
                                                    : <span
                                                        className="text-primary fw-bold"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => openTableModal(row, "zone")}
                                                    >
                                                        {row.numberSubdomains}
                                                    </span>)
                                            }
                                        ]
                                    }
                                    formatedData={dataTableZones}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                    openTableModal={openTableModal}
                                    table={"zone"}
                                />
                                :
                                <Paper variant="outlined" className="container-table-config">
                                    <ActionModal
                                        icon={<img alt="no-zones" src="/img/no-zones.svg" />}
                                        logicPrimary={handleOpenZoneModal}
                                        text={
                                            <>
                                                {t('noZones1')}<br />
                                                <span style={{ fontSize: '0.9rem' }}>{t('noZones2')}</span><br />
                                            </>
                                        }
                                        textPrimary={"+ " + t('addZone')}
                                    />
                                </Paper>
                    }
                </Col>
                <Col md={6}>
                    <div className="d-flex align-items-baseline justify-content-between mb-3" style={{ minHeight: "45px" }}>
                        <h5>{t('groups')}</h5>
                        {Object.keys(groups).length !== 0 && !isLoadingGroups && <Button className="button_config" onClick={openGroupModal} variant="primary">+ {t('addGroup')}</Button>}
                    </div>

                    {
                        isLoadingGroups
                            ?
                            <ConfigurationSkeleton />
                            :
                            Object.keys(groups).length !== 0 && Object.keys(dataTableGroups).length !== 0
                                ?
                                <Table
                                    columns={
                                        [
                                            {
                                                title: "Nombre",
                                                field: "name",
                                                render: row =>
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleEdit(row, "group")}
                                                    >
                                                        {row.name}
                                                    </div>
                                            },
                                            {
                                                title: "Dominios",
                                                field: "numberDomains",
                                                render: row =>
                                                (row.numberDomains === 0 ?
                                                    "-"
                                                    : <span
                                                        className="text-primary fw-bold"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => openTableModal(row, "group")}
                                                    >
                                                        {row.numberDomains}
                                                    </span>)

                                            }
                                        ]
                                    }
                                    formatedData={dataTableGroups}
                                    handleEdit={handleEdit}
                                    openTableModal={openTableModal}
                                    table={"group"}
                                    handleDelete={handleDelete}
                                />
                                :
                                <Row>
                                    <Paper variant="outlined" className="container-table-config">
                                        <ActionModal
                                            icon={<img alt="no-groups" src="/img/no-groups.svg" />}
                                            text={
                                                <>

                                                    {t('noGroups1')}<br />
                                                    <span style={{ fontSize: '0.9rem' }}>{t('noGroups2')}</span><br />
                                                </>
                                            }
                                        />
                                        <div className="text-center">
                                            <Button className="button text-center"
                                                onClick={openGroupModal}>{"+ " + t('addGroup')}</Button>
                                        </div>
                                    </Paper>
                                </Row>
                    }
                </Col>
            </Row>
            <Row>
                <Col className="text-center pt-5">
                    <Button variant="outline-primary" className="button" onClick={() => navigate("/homeDNS")}>{t('goBack')}</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default Configuration;
