import React, {useEffect, useState} from "react";
import {Button, Container, Form, Row} from "react-bootstrap";
import "../css/NewDomain.css";
import {useForm} from "react-hook-form";
import ZoneModal from "../components/commonUtils/modals/ZoneModal";
import GroupModal from "../components/commonUtils/modals/GroupModal";
import * as api from "../components/services/api";
import {useNavigate, useParams} from "react-router-dom";
import UserInfo from "../components/newDomainForm/userInfo";
import GroupSelect from "../components/newDomainForm/domainInfo/groupSelect";
import IpDirection from "../components/newDomainForm/ipDirection";
import DomainInfo from "../components/newDomainForm/domainInfo/domainInfo";
import ZoneSelect from "../components/newDomainForm/domainInfo/zoneSelect";
import ActionModal from "../components/commonUtils/modals/ActionModal";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Modal from "react-modal";
import { ipWaitingDevice } from "../utils/ConstUtils";
import NewDomainSkeleton from "./Skeletons/NewDomainSkeleton";
import { useTranslation } from "react-i18next";

const config = require('../config/config');

/**
 * /domain
 * Pagina mostrada cuando se desea añadir un nuevo dominio.
 * 
 * @param {*} props 
 * @returns {Container}
 */
const NewDomain = (props) => {
    const {t} = useTranslation('safirednstranslation');
    const [monitorizeDevice] = useState(false);

    // Se crea un array con las diferentes propiedades utilizadas de las zonas.
    const [parsedZones,setParsedZones]= useState([]);

    // Se almacenan las zonas despues de llamar al endpoint.
    const [zones,setZones]= useState([]);
    const [state, setState] = useState(
        {
            id: "",
            domain: "",
            zone: {
                id: config.domains.defaultZoneId,
                name: config.domains.defaultZone
            },
            group: {
                id: "",
                name: ""
            },
            resourcerecords: {
                ipv4: {
                    value: ipWaitingDevice
                },
                ipv6: {
                    value: ""
                }
            },
            username: "",
            password: "",
            name: "",
            deviceName:"",
            port:"",
            webPort:"",
            deviceUser:"",
            devicePassword:""
        });

    // Propiedades utilizadas para react-hook-form
    const {register, handleSubmit, clearErrors, formState: {errors}, setValue, setError, resetField} = useForm();

    // Mostrar modal de grupo cuando se desee añadir o editar.
    const [showGroupModal, setShowGroupModal] = useState(false);

    // Mostrar modal de zona cuando se desee añadir o editar.
    const [showZoneModal, setShowZoneModal] = useState(false);

    // Se almacenan los grupos despues de llamar al endpoint.
    const [groups,setGroups]=useState([]);

    /**
     * Si se desea eliminar un dominio utilizamos este objeto. 
     * delete -> intencion de borrar 
     * showError -> si algo no va bien
     * confirmed -> se ha borrado
     * */
    const [deleteArea, setDeleteArea] = useState({delete: false, showError: false, confirmed: false});

    // En caso de que el guardado o actualizacion del dominio no hayan funcionado como se desea, mostrar modal con la informacion recogida en este objeto. 
    const [showSave, setShowSave] = useState({nameDomain:"", showError: false, errorMessage: ""}) 
    let navigate=useNavigate();

    // Se utiliza este objeto para comprobar si el nombre de un dominio se encuentra disponible.
    const [zoneTemp, setZoneTemp] = useState({previousId: config.domains.defaultZoneId, previousZone: config.domains.defaultZone, actualId: config.domains.defaultZoneId, actualZone: config.domains.defaultZone, initialZone: config.domains.defaultZone});

    // Se utiliza este objeto para gestionar la busqueda del nombre de dominio.
    const [searchDomain, setSearchDomain] = useState({showSpinner: false, response: false, valid: false});

    // Objeto para mostrar los skeletons hasta tener datos.
    const [isLoadingDomain, setIsLoadingDomain] = useState(true);

    // Objeto para mostrar los skeletons hasta tener datos.
    const [isLoadingGroups, setIsLoadingGroups] = useState(true);

    // Objeto para mostrar los skeletons hasta tener datos.
    const [isLoadingZones, setIsLoadingZones] = useState(true);

    // Obtener por parametro el id del dominio.
    const { domainId = "" } = useParams();
    
    const [limitZones, setLimitZones] = useState(null)

    // Monitorizacion de dispositivo
    useEffect(()=>{
        if(monitorizeDevice){
            setState(
                {...state,
                    deviceName:"",
                    port:"",
                    webPort:"",
                    deviceUser:"",
                    devicePassword:""
                });
        }
    },[monitorizeDevice]); // eslint-disable-line react-hooks/exhaustive-deps

    // En caso de que se trate de un dominio nuevo o no, tendra un objeto con diferente estructura.
    useEffect(()=>{
        if(props.domainId==="" && domainId === ""){
            setState({
                id: "",
                domain: "",
                zone: {
                    id: config.domains.defaultZoneId,
                    name: config.domains.defaultZone
                },
                group: {
                    id: "",
                    name: ""
                },
                resourcerecords: {
                    ipv4: {
                        value: ipWaitingDevice
                    },
                    ipv6: {
                        value: ""
                    }
                },
                username:"",
                password:"",
                name: ""
            });
            setIsLoadingDomain(false);
        }else{
            var id = props.domainId !== "" ? props.domainId : domainId
            api.getDomain(id).then(result=>{
                let parsedData={};
                //Adaptar el objeto a nuestra estructura
                parsedData=result;
                // parsedData.ipDirection=result.resourcerecords.ipv4 !== null ? result.resourcerecords.ipv4?.value : result.resourcerecords.ipv6?.value || '';
                // delete parsedData["resourcerecords"];
                if(parsedData.group === null) {
                    parsedData.group = {id: "", name: ""}
                }
                Object.entries(parsedData).forEach(([key,value])=>{
                    setValue(key,value);
                })
                setValue("","")
                setState(parsedData);
                setZoneTemp({previousZone: result.zone.name, actualZone: result.zone.name, initialZone: result.zone.name})
                setIsLoadingDomain(false);
            }).catch(error => {
                navigate("/error")
            });
        }
    },[props.domainId, domainId]);     // eslint-disable-line react-hooks/exhaustive-deps

    // Se recogen los grupos, zonas y dominios.
    useEffect(()=>{
        api.getZonesWithParents().then(result=> {
            setZones(result);
            setIsLoadingZones(false)
            api.getUserInfo().then(userInfo => {
                const createdZones = result.filter(zone => zone.hasOwnProperty('parent'))
                setLimitZones(userInfo.quota.maxZones <= createdZones.length)
            })
        });
        api.getGroups().then(result=>{
            setGroups(result);
            setIsLoadingGroups(false)
        });
    },[]);

    // Se pueden editar todas las zonas excepto sddns1, sddns2 y rfidcreator.
    useEffect(()=>{
        let parsedZones=[];
        zones.forEach(zone=>{
            let editable=false;
            if(zone.parentId!==null){
                editable=true;
            }
            parsedZones.push({id:zone.name,name:zone.domain,editable:editable});
        });
        setParsedZones(parsedZones);
    },[zones]);

    function openGroupModal(group) {
        setState({
            ...state,
            group:group
        })
        setShowGroupModal(true);
    }

    function closeGroupModal(group) {
        setState({...state,
            group:group
        });
        setShowGroupModal(false);
    }

    function closeZoneModal(zone) {
        setState({
            ...state,
            zone:zone === undefined ? state.zone : zone
        })
        setShowZoneModal(false);
    }

    function openZoneModal(zone) {
        const emptyZone = {
            "id": "",
            "name": ""
        }
        if (JSON.stringify(zone) !== JSON.stringify(emptyZone)){
            setState({
                ...state,
                zone:zone
            })
        }

        setShowZoneModal(true);
    }

    // Actualizar todas las zonas despues de haber realizado una creacion, actualizacion o borrado de zonas.
    function refreshZones(){
        api.getZonesWithParents().then(result=>{
            setZones(result);
        })
    }

    // Actualizar todas los grupos despues de haber realizado una creacion, actualizacion o borrado de grupos.
    function refreshGroups(){
        api.getGroups().then(result=>{
            setGroups(result);
        });
    }

    // Se establece una ip por defecto en caso de que no se haya rellenado una ip.
    const checkIps = (resourcerecords) =>  {
        var ips = resourcerecords;
        if((ips.ipv4 === null || ips.ipv4?.value === '') && (ips.ipv6 === null || ips.ipv6?.value === '')) {
            state.resourcerecords = ({ipv4: {value:ipWaitingDevice}})
        }
    }

    // Al guardar un dominio se comprueba si es una actualizacion o una creacion.
    const onSubmit = d => {
        state.domain = state.domain.trim()
        checkIps(state.resourcerecords)
        let finalData={...state};
        let nameDomain = parsedZones.find(z => z.id === state.zone.id)
        finalData.domain = showSave.nameDomain !== '' ? showSave.nameDomain : state.domain.trim() + '.' + nameDomain.name
        let save = {domainId:'', update: false, showError: false, errorMessage: ""};
        let id = "";
        id = props.domainId !== "" ? props.domainId : domainId
        if(id===''){
            api.createDomain(state).then(result => {
                setState({...state, domain: result.name})
                navigate("/infoConfig/" + result.name, {state: {text: t('createdDomain')}})
            }).catch((error) => {
                setShowSave({...save, nameDomain: finalData.domain, errorMessage: error.message, showError: true})
            });
        }else{
            api.updateDomain(id, state).then(result=>{
                setState({...state, domain: state.domain.trim() + '.' + nameDomain.name})
                if(result!==undefined){
                    navigate("/homeDNS", {state: {text: t('updatedDomain', {name: result.name})}})
                }else{
                    setShowSave({...save, nameDomain: finalData.domain})
                    
                }
            }).catch((error) => {
                setShowSave({...save, nameDomain: finalData.domain, errorMessage: error.message, showError: true})
            });
        }
    }

    return (
        <Container fluid>
            <Modal ariaHideApp={false} isOpen={showSave.showError} className="zone-modal" overlayClassName="subdomain-overlay">
                <ActionModal
                    logicPrimary={() => setShowSave({...showSave, errorMessage: "", showError: false, update: false})}
                    icon={<PriorityHighIcon style={{ fontSize: "100px", color:"#D33E4C"}}/>}
                    text={showSave.errorMessage}
                    textPrimary="Atrás"
                />
            </Modal>
            <>
                <ZoneModal 
                    closeModal={closeZoneModal} 
                    deleteArea={deleteArea}
                    zoneTemp={zoneTemp}
                    refreshZones={refreshZones}
                    state={state}
                    setDeleteArea={setDeleteArea} 
                    setZoneTemp={setZoneTemp} 
                    setError={setError}
                    setState={setState}
                    showZoneModal={showZoneModal}
                    zoneId={state.zone?.id}
                    zones={zones}
                />
                <GroupModal 
                    closeModal={closeGroupModal}
                    deleteArea={deleteArea}
                    groupId={state?.group?.id}
                    showGroupModal={showGroupModal}
                    state={state} 
                    refreshGroups={refreshGroups}
                    setDeleteArea={setDeleteArea}
                    setState={setState}
                />
                <div className={"div-form"}>
                {isLoadingDomain || isLoadingGroups || isLoadingZones
                ?
                    <NewDomainSkeleton/>
                :
                    <Form onSubmit={handleSubmit(onSubmit)} className={"form"}>
                            {
                                domainId === "" && (props.zoneId === "" || props.zoneId === undefined) ?
                                <h4 className="text-primary title-page">{t('addDomain')}</h4>
                                :
                                <h4><span className="text-primary title-page">{t('edit')}:</span> {state.name}</h4>
                            }
                        <Row className="mb-3">
                            <div className="mb-2 mt-4">
                                <span className="fw-bold">{t('domain')}*</span>
                            </div>
                            <div className="containerDomain">
                                <DomainInfo 
                                    clearErrors={clearErrors} 
                                    errors={errors}
                                    register={register} 
                                    resetField={resetField}
                                    searchDomain={searchDomain}
                                    setError={setError} 
                                    setSearchDomain={setSearchDomain}
                                    setState={setState} 
                                    state={state} 
                                    zoneTemp={zoneTemp} 
                                />
                                <ZoneSelect
                                    errors={errors} 
                                    openZoneModal={openZoneModal} 
                                    parsedZones={parsedZones}
                                    limitZones={limitZones}
                                    setState={setState} 
                                    setZoneTemp={setZoneTemp}
                                    state={state} 
                                    zoneTemp={zoneTemp} 
                                />
                            </div>
                        </Row>
                        <GroupSelect groups={groups} openGroupModal={openGroupModal} setState={setState} state={state}/>

                        <IpDirection 
                            clearErrors={clearErrors}
                            errors={errors} 
                            register={register}
                            setState={setState} 
                            setValue={setValue}
                            state={state} 
                        />

                        <UserInfo state={state} setState={setState}
                                errors={errors} clearErrors={clearErrors} register={register} />

                        {/* <MonitorizeDevice state={state} setState={setState} errors={errors} register={register} clearErrors={clearErrors}/> */}
                        <div className="text-center mt-4">
                            <Button
                                className="button"
                                disabled={searchDomain.showSpinner}
                                key={"domain-form-back"}
                                onClick={() => navigate(-1)}
                                variant="outline-primary">
                                    {t('goBack')}
                            </Button>
                            <Button
                                className="button"
                                disabled={searchDomain.showSpinner}
                                key={"domain-form-submit"}
                                type="submit"
                                variant="primary">
                                    {t('save')}
                            </Button>
                        </div>
                    </Form>
                }
                </div>
            </>
        </Container>
    );
}

export default NewDomain;
