import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import "../css/Error.css";
import { useTranslation } from 'react-i18next';

/**
 * /error
 * Pagina de error
 * Se redirige a esta pagina cuando algo no ha ido como deberia.
 * 
 * @returns {div}
 */
const Error=()=> {
    const {t} = useTranslation('safirednstranslation');

    let navigate = useNavigate();

    return(
        <div className='container'>
            <div className="text-center">
                <img alt="Error" src="/img/error.svg" style={{maxHeight:"300px"}}/>
                <h2 className='text-primary mt-5'>{t('somethingBad')}</h2>
                <h5>{t('tryLater')}</h5>
                <Button 
                    className="button mt-5" 
                    onClick={() => navigate("/homeDNS")} 
                    variant="primary"
                >
                    {t('goBackDomains')}
                </Button>
            </div>
        </div>
    );

}

export default Error;
