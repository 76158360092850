import Modal from "react-modal";
import React from "react";
import "../css/Configuration.css";
import * as api from "../components/services/api";
import ActionModal from "../components/commonUtils/modals/ActionModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * Modal para confirmar si se borra un dominio.
 * 
 * @param {*} props 
 * @returns {Modal}
 */
function ConfirmDeleteModal({close, domain, handleDelete, show}) {
    const {t} = useTranslation('safirednstranslation');
    
    let navigate = useNavigate();

    // Si ha ido todo bien, se borra el dominio.
    const onDeleteDomain = () => {
        api.deleteDomain(domain.name).then(() => {
            close()
            navigate("/homeDNS", {state: {text: t('deletedDomain', {name: domain.name})}})
        })

    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={show}
            overlayClassName="subdomain-overlay"
        >
            <ActionModal
                text={t('questionDeleteDomain', {name: domain?.name})}
                textPrimary={t('cancel')}
                logicPrimary={close}
                textOutline={t('delete')}
                logicOutline={handleDelete || onDeleteDomain}
            />
        </Modal>
    );
}

export default ConfirmDeleteModal;
