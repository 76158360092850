import React, { useState, useRef, useEffect, memo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../css/ag-grid-dropdown.css";
import Button from "react-bootstrap/Button";
import SearchBox from "../components/commonUtils/search-box";
import Dropdown from "react-bootstrap/Dropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Form from "react-bootstrap/Form";
import * as api from "../components/services/api";
import ConfirmDeleteModal from "../utils/confirmDeleteModal";
import Modal from "react-modal";
import { Spinner } from "react-bootstrap";
import ActionModal from "../components/commonUtils/modals/ActionModal";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { ipWaitingDevice } from "../utils/ConstUtils";
import "../css/Home.css";
import { formatDate } from "../utils/CommonMethods";
import HomeSkeleton from "./Skeletons/HomeSkeleton";
import { useTranslation } from "react-i18next";
import AlertAction from "../components/commonUtils/AlertAction";
import DeviceStates from "../components/commonUtils/DeviceStates";

const config = require('../config/config');
/**
 * /
 * Pagina principal
 * Una vez que el usuario haya hecho login, aparecera una pagina con una tabla y su lista de dominios.
 *
 * @returns {div}
 */
const Home = () => {
  const { t } = useTranslation("safirednstranslation");

  // Utilizado para cargar la tabla de dominios.
  const [gridApi, setGridApi] = useState(null);

  // Filtrar en la tabla.
  const [quickFilterText, setQuickFilterText] = useState("");

  // Almacenar los grupos obtenidos para mostrar en el selector.
  const [groups, setGroups] = useState([]);

  // Se almacenan los dominios obtenidos por endpoint.
  const [domains, setDomains] = useState([]);

  // Mostrar modal al borrar un dominio.
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Guardamos el nombre del dominio a borrar para despues mostrar a la hora de confirmar.
  const [domainToDelete, setDomainToDelete] = useState();

  // Para copiar el nombre de dominio y guardarlo en el portapapeles.
  // const [copiedText, setCopiedText] = useState({ state: false, row: "" });

  // Comprobar si se ha superado el numero maximo de dominios permitidos.
  const [showMax, setShowMax] = useState(false);

  // En el primer renderizado, no hacemos nada, despues ya realizamos las acciones cuando se haya montado.
  const isMounted = useRef(false);

  const proxyLastFetchTimes = useRef({});

  let navigate = useNavigate();

  useEffect(() => {
    getDomains();
  }, []);

  // Obtener los dominios llamando al endpoint.
  const getDomains = async () => {
    api.getDomains().then((result) => {
      //Recorremos los dominios para sacar los grupos distintos y no hacer 2 llamadas a la api aprovechando
      //que ya los tenemos

      //Utilizamos flatMap para poder filtrar a la vez que recorremos los dominios que no tienen grupo y
      //así no guardarlos en el array que devuelve, trata cada posición como un array y se puede hacer
      //operaciones con él (sino  tendríamos que hacer filter y luego map)
      let groupsDomains = result.flatMap((domain) =>
        domain.group ? domain.group.name : []
      );
      //Función que devuelve solo los distinct de un array, para que no haya grupos duplicados
      let groupsDistinct = [...new Set(groupsDomains)];

      setGroups(groupsDistinct);
      setDomains(result);
      isMounted.current = true;
    });
  };

  // Renderizado del estado de dispositivo.
  const StatusRenderer = (cell) => {
    const [proxyStatus, setProxyStatus] = useState(null);

    useEffect(() => {
      const getStatus = async () => {
        const currentTime = Date.now();
        if (domainToDelete?.name === cell.data.name) return;
        if (cell.data.name in proxyLastFetchTimes.current && currentTime - proxyLastFetchTimes.current[cell.data.name].time < config.proxy.cacheTimeMs) {
          setProxyStatus(proxyLastFetchTimes.current[cell.data.name].status);
          return;
        }

        // Por defecto se asigna el estado de esperando conexion.
        try {
          const result = await api.getProxyInfo(cell.data.name);
          const status = result?.data?.status || "waiting";
          proxyLastFetchTimes.current[cell.data.name] = { time: currentTime, status: status };
          setProxyStatus(status);
        } catch (error) {
          console.error("Error proxy info: ", error);
          setProxyStatus("badagent");
        }
      }
      getStatus();
    }, [cell.data.name, domainToDelete]) // eslint-disable-line react-hooks/exhaustive-deps

    // Se renderiza un componente u otro dependiendo si tenemos ya el estado.
    const renderIcon = () => {
      return proxyStatus === null ? (
        <div className="text-center">
          <Spinner animation="border" size="sm" />
        </div>
      ) : (
        <DeviceStates proxyStatus={proxyStatus} iconStyle="fs-5" onlyIcon />
      );
    };
    return renderIcon();
  };

  const ipGetter = (params) => {
    const ipv4Value = params.data.resourcerecords?.ipv4?.value;
    const ipv6Value = params.data.resourcerecords?.ipv6?.value;
    const ipv4LastDeviceUpdate = params.data.resourcerecords?.ipv4?.lastDeviceUpdate;
    const ipv6LastDeviceUpdate = params.data.resourcerecords?.ipv6?.lastDeviceUpdate;

    // en el caso de que no exista ninguna, se pone un -
    if (!ipv4Value && !ipv6Value) {
      return '-';
    }

    // si solo existe una, ponemos la que exista
    if (ipv4Value && !ipv6Value) {
      return ipv4Value;
    }

    if (ipv6Value && !ipv4Value) {
      return ipv6Value;
    }

    // en caso de que existan las dos, entonces comparamos las fechas
    // si la v6 no se ha actualizado nunca, entonces retornamos la ip v4
    // este caso también cubre cuando ninguna se ha actualizado
    if (!ipv6LastDeviceUpdate) {
      return ipv4Value;
    }

    // si la v4 no se ha actualizado nunca, entonces retornamos la ip v6
    if (!ipv4LastDeviceUpdate) {
      return ipv6Value;
    }

    // si las dos se han actualizado, cogemos la última
    if (ipv4LastDeviceUpdate >= ipv6LastDeviceUpdate) {
      return ipv4Value;
    } else {
      return ipv6Value;
    }
  }

  const ipLastUpdateGetter = (params) => {
    const ipv4LastDeviceUpdate = params.data.resourcerecords?.ipv4?.lastDeviceUpdate;
    const ipv6LastDeviceUpdate = params.data.resourcerecords?.ipv6?.lastDeviceUpdate;

    if (!ipv4LastDeviceUpdate && !ipv6LastDeviceUpdate) {
      return '-';
    }

    if (!ipv6LastDeviceUpdate) {
      return formatDate(ipv4LastDeviceUpdate);
    }

    if (!ipv4LastDeviceUpdate) {
      return formatDate(ipv6LastDeviceUpdate);
    }

    if (ipv4LastDeviceUpdate >= ipv6LastDeviceUpdate) {
      return formatDate(ipv4LastDeviceUpdate);
    } else {
      return formatDate(ipv6LastDeviceUpdate);
    }
  }

  // //Renderizado columna IP
  const ipRenderer = (cell) => {
    return cell.value === ipWaitingDevice ? (
      <span style={{ fontStyle: "italic" }}>{t("waitingIP")}</span>
    ) : (
      cell.value
    );
  };

  //Renderizado del botón de opciones, al clickar mostrara las diferentes acciones a elegir.
  const ButtonRenderer = memo(({ data }) => {
    return (
      <Dropdown className="dropdown-table-config">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          <MoreVertIcon style={{ color: "#0000008a" }} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            as={Link}
            className="item"
            to={"/infoConfig/" + data?.name}
          >
            <VisibilityIcon /> {t("showInformation")}
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            className="item"
            to={"/domain/" + data?.name}
          >
            <EditIcon /> {t("edit")}
          </Dropdown.Item>
          <Dropdown.Item className="item" onClick={() => deleteDomain(data)}>
            <DeleteIcon /> {t("remove")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  });

  //Mostrar dominios e icono de copiar dominio
  const domainRenderer = (cell) => {
    return (
      <div>
        <Link
          to={"/infoConfig/" + cell.value}
          style={{ textDecoration: "none", fontWeight: "bold" }}
        >
          {cell.value}
        </Link>
      </div>
    );
  };

  // Columnas a mostrar en la tabla.
  const columnsTable = [
    {
      headerName: t("domain"),
      field: "name",
      flex: 2,
      cellRenderer: domainRenderer,
      wrapText: true,
      // cellRendererParams: { copiado: copiedText },
    },
    {
      headerName: t("device"),
      cellRenderer: StatusRenderer,
      headerClass: "header-center",
      flex: 1,
    },
    {
      headerName: t("ip"),
      valueGetter: ipGetter,
      cellRenderer: ipRenderer,
    },
    {
      headerName: t("changeIP"),
      valueGetter: ipLastUpdateGetter,
    },
    {
      headerName: t("group"),
      field: "group.name",
      hide: groups.length <= 0,
    },
    {
      cellRenderer: ButtonRenderer,
      cellClass: "dropDownCell",
      maxWidth: "80",
    },
  ];

  //Función que se ejecuta automáticamente cuando se termina de cargar la tabla
  function onGridReady(params) {
    setGridApi(params.api);
    if (quickFilterText !== "") params.api.setQuickFilter(quickFilterText);
  }

  //Valor inicial del desplegable de estados para que hasta que no sea diferente a este valor no se ejecute
  //la función de filtrado
  const filterState = useRef("");

  //Cambio en el input de búsqueda
  function onChangeFilter(text) {
    setQuickFilterText(text);
    gridApi.setQuickFilter(text);
  }

  //Cuando cambia el selector de filtro de estado de las sims
  const onChangeFilterState = (e) => {
    filterState.current = e.target.value;
    //Avisamos a la tabla de que aplique el filtro
    gridApi.onFilterChanged();
  };

  //Cuando esta función es true, llama a que se comprueben los filtros o busquedas, sino no actúa
  //en este caso cuando el desplegable tiene algún valor
  const isExternalFilterPresent = () => {
    return filterState.current !== "";
  };

  //Función que se activa cuando llamamos al onFilterChanged()
  const doesExternalFilterPass = (node) => {
    return node.data.group
      ? node.data.group.name === filterState.current
      : false;
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      variant="link"
    >
      {children}
    </Button>
  ));

  function closeDeleteModal() {
    setShowDeleteModal(false);
    setDomainToDelete(null);
  }

  const deleteDomain = (domain) => {
    setDomainToDelete(domain);
    setShowDeleteModal(true);
  };

  // Si ha ido todo bien, se borra el dominio.
  const handleDelete = () => {
    api.deleteDomain(domainToDelete.name).then(() =>{
      const newDomains = domains.filter(domain => domain.name !== domainToDelete.name)
      closeDeleteModal();
      setDomains(newDomains);
      navigate("/homeDNS", {state: {text: t('deletedDomain', {name: domainToDelete.name})}})
    });
  }

  // Antes de crear un nuevo dominio, es necesario conocer si no ha superado el numero maximo de dominios.
  const checkMaxDomains = () => {
    api.getUserInfo().then((response) => {
      return domains.length < response.quota.maxDomains
        ? navigate("/domain")
        : setShowMax(true);
    });
  };

  return (
    <div style={{ maxWidth: "1200px", margin: "auto" }}>
      <Modal
        ariaHideApp={false}
        isOpen={showMax}
        className="zone-modal"
        overlayClassName="subdomain-overlay"
      >
        <ActionModal
          logicPrimary={() => setShowMax(false)}
          icon={
            <PriorityHighIcon style={{ fontSize: "100px", color: "#D33E4C" }} />
          }
          text={t("limitDomains")}
          textPrimary={t("goBack")}
        />
      </Modal>
      <AlertAction style={{ margin: "30px auto 40px", width: "90%" }} />
      {isMounted.current ? (
        domains === undefined || domains.length >= 0 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                margin: "30px auto 40px",
                width: "90%",
                flexWrap: "wrap",
              }}
            >
              {groups.length > 0 && (
                <Form.Select
                  aria-label="Groups"
                  onChange={onChangeFilterState}
                  style={{ width: "auto" }}
                >
                  <option value="" key="0">
                    {t("allGroups")}
                  </option>
                  {groups.length > 0 &&
                    groups.map((zone) => (
                      <option value={zone} key={zone}>
                        {zone}
                      </option>
                    ))}
                </Form.Select>
              )}
              <SearchBox onChange={onChangeFilter} />

              <Button
                className="button"
                variant="primary"
                onClick={checkMaxDomains}
                style={{ padding: "0.5rem 1em" }}
              >
                + {t("addDomain")}
              </Button>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-zones-groups">
                  <MoreVertIcon style={{ color: "#0000008a" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/config">
                    <EditIcon /> {t("editGroupsAndZones")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="ag-theme-material"
              style={{
                height: 600,
                width: "90%",
                margin: "auto",
                marginBottom: "40px",
              }}
            >
              <AgGridReact
                columnDefs={columnsTable}
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
                onGridReady={onGridReady}
                rowData={domains}
                rowBuffer={10}
                defaultColDef={{
                  suppressMovable: true,
                  sortable: true,
                  flex: 1,
                  headerClass: "header",
                  cellStyle: { color: "#908F8F" },
                }}
                suppressCellFocus={true}
              />
            </div>
          </>
        ) : (
          <div className="nodomain_container">
            <div className="text-center py-5">
              <img
                alt="Safire services logo"
                src="/img/logo_camara.svg"
                style={{ maxHeight: "100px" }}
              />
              <p className="my-4 fw-bold">
                {t("noDomains1")} <br />
                {t("noDomains2")}
              </p>
              <Button
                className="button"
                onClick={checkMaxDomains}
                type="submit"
                variant="primary"
              >
                + {t("addDomain")}
              </Button>

            </div>
          </div>
        )
      ) : (
        <HomeSkeleton />
      )}
      <ConfirmDeleteModal
        show={showDeleteModal}
        close={closeDeleteModal}
        domain={domainToDelete}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default Home;
