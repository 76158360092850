import React, {useEffect, useState} from "react";
import {FloatingLabel, Form, InputGroup, Spinner} from "react-bootstrap";
import { regexAlfanumeric } from "../../../utils/ConstUtils";
import * as api from "../../services/api";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BlockIcon from '@mui/icons-material/Block';
import { useRef } from "react";
import { useTranslation } from "react-i18next";

/**
 * Esta funcion se utiliza para el valor del nombre de dominio a la hora de añadir o editar.
 * 
 * @param {*} props 
 * @returns {Form.Group}
 */
export default function DomainInfo(props){
    const {t} = useTranslation('safirednstranslation');
    
    // Se guarda el nombre del dominio para mostrar en el input.
    const [nameTemp, setNameTemp] = useState("")
    
    // Nos aseguramos de que la primera vez que renderiza no haga nada.
    const isMounted = useRef(false);

    const {zoneTemp, errors, register, resetField, searchDomain, setError, setSearchDomain, setState, state} = props;

    // Cuando se actualice el nombre en el estado, actualizamos para el input y para el propio estado.
    useEffect(()=>{
        setNameTemp(state.name?.split(".")[0])
        setState({...state, domain: state.name?.split(".")[0]})
    },[state.name]); // eslint-disable-line react-hooks/exhaustive-deps

    // Si se actualiza el dominio, se comprueba que esté libre.
    useEffect(() => {
        if(isMounted.current) {
        
            /* Si el nombre de dominio o la zona han sido cambiados, realizamos comprobación de nombre de dominio.
            * 1. Si el nombre no esta vacio Y 
            *   2.1 el id esta vacio O
            *   2.2 el id no esta vacio Y 
            *       2.2.1 el nombre del input es distinto del que hay en el estado O
            *           2.2.1.1 la zona actual no coincide con la que habia seleccionada anteriormente Y
            *           2.2.1.2 la zona incial es diferente a la del estado 
            */
            if(nameTemp !== '' && state.zone.name !== '' && checkName(state, nameTemp, zoneTemp)) {
                setSearchDomain({showSpinner: true, response: false, valid: null})
            }
        } else {
            isMounted.current = true;
        }
    }, [state.zone.name]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkName = (state, nameTemp, zoneTemp) => {
        return state.id === '' ||
            (state.id !== '' && (state.name?.split(".")[0] !== nameTemp || (zoneTemp.actualZone !== zoneTemp.previousZone && zoneTemp.initialZone !== state.zone.name)));
    }

    // Llamada a api si el dominio ya existe en base de datos.
    const checkDomain = () => {
            // La longitud del dominio debe ser de minimo 2 caracteres
            if(nameTemp.length < 2) {
                setError('domain', { type: 'server side', message: t('validateTwoChars') })
                setSearchDomain({showSpinner: false, response: true, valid: false})
            } else if(!regexAlfanumeric.test(nameTemp)) { // Regex para comprobar los caracteres validos.
                setError('domain', { type: 'server side', message: t('validateAlfanumeric') })
                setSearchDomain({showSpinner: false, response: true, valid: false})
            } else {
                let domain = nameTemp + '.' + zoneTemp.actualZone
                /* Se comprueba si el dominio esta libre.
                * domainfree: dominio disponible | domaintaken: ya en uso.
                */
                api.checkDomainName(domain).then(result => {
                    if(result === 'domainfree') {
                        setSearchDomain({showSpinner: false, response: true, valid: true})
                        resetField('domain')
                    } else {
                        setSearchDomain({showSpinner: false, response: true, valid: false})
                        setError('domain', { type: 'server side', message: t('validateDomainInUse') }, { shouldFocus: true })
                    }
                });
            }
    }
    

    // En caso de que se actualice el nombre de domino o de la zona, comprobamos si es posible comprobar el dominio.
    useEffect(() => {
            if(nameTemp !== '' && zoneTemp.actualId !== 'newZone' &&
                checkName(state, nameTemp, zoneTemp)
            ) {
                const delay = setTimeout(() => { 
                    checkDomain()
                }, 2000);

                return () => (clearTimeout(delay))
            } else {
                setSearchDomain({showSpinner: false, response: null, valid: null})
                resetField('domain')
            }
    }, [nameTemp, zoneTemp]) // eslint-disable-line react-hooks/exhaustive-deps

    // Guardamos el valor de los inputs cuando se actualiza.
    function handleChange(e) {
        setState({...state, [e.target.id]: e.target.value})
        
        if(e.target.id==='domain') {
            setNameTemp(e.target.value)
            setSearchDomain({showSpinner: true, response: false, valid: null})
            resetField('domain')
        }
    }

    return(
        <>

            <Form.Group controlId="domain">
                <InputGroup>
                    <FloatingLabel
                        controlId="domain"
                        label={t('domain')}
                        style={{flex: '1'}}
                    >
                        <Form.Control
                            {...register("domain", {
                                onChange: e => handleChange(e),
                                validate: value => value === undefined || (value !== undefined && value.length>=2) || t('validateTwoChars'),
                                pattern: {value: regexAlfanumeric, message: t('validateAlfanumeric')},
                                required: {value: nameTemp === "", message: t('validateDomain')},
                            })}
                            autoFocus
                            className="input-check-button"
                            maxLength={50}
                            placeholder={t('domain')}
                            value={nameTemp || ''}
                        />
                    </FloatingLabel>
                    {//En caso de que el nombre de dominio haya sido solicitado, dependiendo de si esta libre o no, mostramos un icono en el input
                    searchDomain.response &&
                        <InputGroup.Text className="check-button">
                                {searchDomain.valid && !errors.hasOwnProperty("domain") 
                                    ? <TaskAltIcon className="text-success"/> 
                                    : <BlockIcon className="text-danger"/>
                                }
                        </InputGroup.Text>
                    }
                    {//Si esta cargando la respuesta del nombre del dominio, mostrar spinner
                    searchDomain.showSpinner && 
                        <InputGroup.Text className="check-button">
                            <Spinner
                                animation="border"
                                size="sm"
                                variant="primary"
                            />
                        </InputGroup.Text>
                    }
                </InputGroup>
                {errors["domain"] && <span className="text-primary">{errors["domain"]?.message}</span>}
            </Form.Group>
        </>
    );
}
