import { createContext } from "react";

/**
 * El contexto es utilizado para mostrar errores en la app.
 */
const ErrorContext = createContext({
  errorMessage: "",
  setErrorMessage: () => {},
  showErrorDialog: false,
  setShowErrorDialog: () => {},
});

export default ErrorContext;
