import { createContext, useEffect, useState } from "react";
import * as api from "../api";
import React from "react";

/**
 * Este contexto es utilizado para obtener los servicios que puede acceder un usuario.
 *
 * @returns Provider
 */
const ServicesContext = createContext();

const ServicesProvider = ({ children }) => {
  const [services, setServices] = useState({
    procloud: false,
    safiredns: false,
    admin: false,
  });

  const getAvailableServices = async () => {
    // Se almacena en el estado el servicio y si esta disponible recorriendo la respuesta del endpoint y comprobando las dos propiedades que se necesitan.
    let res = {};
    await api.getUserInfoAuth().then((response) => {
      res = response.data.services.reduce(
        (res, service) => ({
          ...res,
          [service.name]: service.user_service.status === "active",
        }),
        {}
      );
    });
    setServices(res);
  };

  useEffect(() => {
    getAvailableServices();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ServicesContext.Provider value={{ services: services }}>
      {children}
    </ServicesContext.Provider>
  );
};

export { ServicesContext, ServicesProvider };
