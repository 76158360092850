import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

/**
 * Modal genérico para guardar, eliminar, actualizar un dominio, zona y/o grupo.
 * 
 * Obligatorio:
 *  _Icono: para mostrar como ha ido la accion
 *  _Texto: informacion para el usuario
 * 
 * Opcional:
 *  _Boton primario: para realizar una accion que se necesite.
 *  _Boton secundario: para realizar una accion que se necesite.
 *  _Icono de cierre: sirve para cerrar el modal.
 * 
 * @param {*} props
 */
const ActionModal = (props) => {
    const {closeIcon, closeModal, handleClose, icon, logicPrimary, logicOutline, text, textPrimary, textOutline} = props;
    const [isDisabled, setIsDisabled] = useState(false);
    
    // Esta propiedad se utiliza para cerrar automaticamente el modal.
    if(closeModal) {
        setTimeout(() => {
            handleClose();
            setIsDisabled(false)
        }, 1000);
    }

    const handleClickPrimary = (e) => {
        setIsDisabled(true) // Se desactiva el boton hasta que no termine la logica que se indique por props.
        logicPrimary(e)
    }

    const handleClickOutline = (e) => {
        setIsDisabled(true)
        logicOutline(e)
    }
    
    return(
        <Row className="py-3">
            {closeIcon && 
                <div md={1} className="d-flex justify-content-end">
                    <CancelOutlinedIcon className="d-flex-end" onClick={props.closeIcon}/>
                </div>
            }
            <div className="text-center">
                <div className="mb-4">{icon}</div>
                <p className="mb-4">{text}</p>
                {textOutline && <Button className="button" disabled={isDisabled} onClick={handleClickOutline} variant="outline-primary">{textOutline}</Button>}
                {textPrimary && <Button className="button" disabled={isDisabled} onClick={handleClickPrimary} type="submit" variant="primary">{textPrimary}</Button>}
            </div>
        </Row>
    );
}

export default ActionModal;
