import { Skeleton } from "@mui/material";
import React from "react";

/**
 * Skeleton de la pagina de configuracion
 * 
 * @returns {div}
 */
const ConfigurationSkeleton = () => {
  return (
        <div className="pt-4">
            <Skeleton height={50} variant="rectangular" />
            <div className="pt-4">
                <Skeleton height={50} variant="text" />
                <Skeleton height={50} variant="text" />
                <Skeleton height={50} variant="text" />
            </div>
        </div>
  );
};

export default ConfigurationSkeleton;
