import { Skeleton } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";

/**
 * Skeleton de la pagina de informacion del dispositivo.
 * 
 * @param {*} props 
 * @returns {Row}
 */
const InfoConfigurationSkeleton = (props) => {

     const {number} = props;

    return (
        <>
            {[...Array(number)].map((_, index) => (
                <Row className="mb-2 px-4" key={index}>
                    <Col>
                        <Skeleton height={30} variant="text" />
                    </Col>
                    <Col className="text-secondary">
                        <Skeleton height={30} variant="text" />
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default InfoConfigurationSkeleton;
