import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HomeProcloud from "../components/HomeSafireServices/HomeProcloud";
import HomeSafireDNS from "../components/HomeSafireServices/HomeSafireDNS";
import HomeServices from "../components/HomeSafireServices/HomeServices";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ServicesContext } from "../components/services/Context/ServicesContext";

const HomeSafireServices = () => {
  const { t } = useTranslation("safirednstranslation");

  const { services } = useContext(ServicesContext);

  return (
    <Container fluid className="pt-5 px-5">
      <HomeServices />
        <Row
          style={{ marginTop: "100px" }}
        >
          {services.procloud && (
            <Col className="col-md-6">
              <HomeProcloud />
              <a
                className="px-4 fw-bold text-primary text-decoration-none"
                href={process.env.REACT_APP_PROCLOUD_FRONT_URL+"/?token="+localStorage.getItem("access_token")}
              >
                {t("goTo")} ProCloud <ArrowForwardIcon />
              </a>
            </Col>
          )}
          {services.safiredns && (
            <Col className="col-md-6">
              <HomeSafireDNS />
              <a
                className="px-4 fw-bold text-primary text-decoration-none"
                href={process.env.REACT_APP_DNS_FRONTEND_URL + "/homeDNS"}
              >
                {t("goTo")} SafireDNS <ArrowForwardIcon />
              </a>
            </Col>
          )}
        </Row>
    </Container>
  );
};

export default HomeSafireServices;
