import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Alert, Col, FloatingLabel, InputGroup} from "react-bootstrap";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import "../css/login.css"
import { useTranslation } from 'react-i18next';
import {refreshToken} from "../utils/auth";
import { LogOut } from './Logout';
//coger de sesion o local el token y el refresh y pedirlo al endpoint para refrescarlo
/*
export const refreshToken = async () => {
    const refreshTokenLocal = localStorage.getItem("refresh_token");

    let refresh_token = {}
    refresh_token['refreshToken'] = refreshTokenLocal


    return axios.post(process.env.REACT_APP_AUTH_URL + "/users/refreshToken", refresh_token)
        .then(function (response) {
            setToken(response.data.token, response.data.refreshToken)
            //initAxios(response.data.token)

            //navigate('/');
        }).catch(function (error) {
            console.log(error);
        });
}
*/

//Guardamos el token y refresh en local y actualizamos las cabeceras de las peticiones a la api
const setToken = (token, refreshToken) => {
    document.cookie = "auth="+token;
    localStorage.setItem("access_token", token);
    localStorage.setItem("refresh_token", refreshToken);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

/**
 * /login
 * Landing page, el usuario se encargara de hacer login para poder acceder al resto de rutas de la app.
 * 
 * @returns {div}
 */
const Login = () => {
    const {t} = useTranslation('safirednstranslation');

    // Se almacena usuario y contraseña para poder hacer login.
    const [loginData, setLoginData] = useState({});

    // Mostrar u ocultar la contraseña.
    const [showPass, setShowPass] = useState(false);

    // En caso de que los datos introducidos no sean correctos, mostrar error.
    const [showError, setShowError] = useState(false);

    //Cambiar icono de mostrar u ocultar contraseña.
    const handleShowPass = () => {
        setShowPass(!showPass)
    }

    const navigate = useNavigate();

    // En el momento que intente iniciar sesion, se rellena con la info recogida.
    const handleForm = (event, formType) => {
        setLoginData((prev) => ({...prev, [formType]: event.target.value}))
    }

    // LLamada al endpoint de login y comprobar la respuesta.
    const onSubmitLogin = () => {
        return axios.post(process.env.REACT_APP_AUTH_URL + "/login", loginData)
            .then(function (response) {
                setToken(response.data.token, response.data.refreshToken)
                navigate('/');
            }).catch(function (error) {
                setShowError(true)
                console.log(error);
            });
    }


    return (
        <div className="body_login">
            <div className="container-login">
                <div className="img-container d-none d-sm-block">
                    <img alt="Login icon" src="../../img/login_map.svg"/>
                </div>
                <div>
                    <div className="container-form-login">
                        <div className="safire_services_logo">
                            <img alt="Safire services logo" src="../../img/safire_services_logo.svg"/>
                        </div>
                        {showError &&
                            <Alert variant='danger'>
                                {t('incorrectDataLogin')}
                            </Alert>
                        }
                        <Form>
                            <Form.Group>
                                <InputGroup className="mb-3">
                                    <FloatingLabel controlId="inputUsuario" label={t('email')}>
                                        <Form.Control type="email" placeholder={t('email')}
                                                      onChange={(e) => handleForm(e, "email")}/>
                                    </FloatingLabel>
                                    <InputGroup.Text >
                                        <MailOutlineIcon/>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group>
                                <InputGroup className="mb-3">
                                    <FloatingLabel controlId="formBasicPassword" label={t('password')}>
                                        <Form.Control type={(showPass) ? "text" : "password"} placeholder={t('password')} onChange={(e) => handleForm(e, "password")}/>
                                    </FloatingLabel>
                                    <InputGroup.Text onClick={handleShowPass} style={{cursor: "pointer"}}>
                                        {showPass ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <div className="forgot-password-container">
                                <Form.Check type="checkbox" id="rememberPasword" label={t('rememberMe')}/>
                                <a href="https://safire.services/remember">{t('questionForgetPassword')}</a>
                            </div>
                            <div className="d-grid mt-5 mb-5">
                                <Button variant="primary" onClick={onSubmitLogin} size="lg" className="login-button">
                                    {t('logIn')}
                                </Button><Button variant="primary" onClick={refreshToken} size="lg" className="login-button">
                                    REFRESH
                                </Button><Button variant="primary" onClick={LogOut} size="lg" className="login-button">
                                    LOGOUT
                                </Button>
                            </div>
                            <div className="text-center">{t('questionCreateAccount')} <a href="https://safire.services/signup">{t('register')}</a></div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;


export const PasswordInput = (props) => {
    const {t} = useTranslation('safirednstranslation');
    const {data, register} = props;
    const [showPass, setShowPass] = useState(false);
    const [valuePass, setValuePass] = useState("")

    useEffect(() => {
        setValuePass(data.password)
    }, [data.password])

    //Cambiar icono de mostrar u ocultar contraseña.
    const handleShowPass = () => {
        setShowPass(!showPass)
    }

    const handleChange = (e) => {
        props.handleChange(e)
    }

    return (
        <Form.Group as={Col} controlId="domainPassword">
            {/* <Form.Label>Contraseña*</Form.Label> */}

            <InputGroup>
                <FloatingLabel
                    controlId="domainPassword"
                    label="Contraseña"
                    className="mb-1"
                >
                    <Form.Control
                        {...register("password", {required: true})}
                        onChange={handleChange}
                        placeholder="Contraseña"
                        type={(showPass) ? "text" : "password"}
                        value={valuePass}
                    />
                </FloatingLabel>
                <InputGroup.Text className="mb-1" onClick={handleShowPass}>
                    {showPass ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                </InputGroup.Text>
            </InputGroup>
            {props.errors["password"] &&
            <span className="text-primary">{t('validateRequiredPassword')}</span>}
        </Form.Group>
    )
}
