import {Col, Row} from "react-bootstrap";
import Modal from "react-modal";
import React, { useState } from "react";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import "../../css/Configuration.css";
import * as api from "../../components/services/api";
import ActionModal from "../commonUtils/modals/ActionModal";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useNavigate } from 'react-router-dom';
import SubdomainTable from "./SubdomainTable";
import { useTranslation } from "react-i18next";

/**
 * Modal utilizado para mostrar los diferentes dominios que existen asociados a las zonas y grupos.
 * 
 * @param {*} props 
 * @returns {Modal}
 */
function SubdomainsModal(props) {
    const {t} = useTranslation('safirednstranslation');
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    
    // Almacenamos el dominio que se va a eliminar.
    const [toDelete, setToDelete] = useState() 

    // En caso de que se haya borrado, utilizamos este estado para mostrar que se ha eliminado correctamente.
    const [deleted, setDeleted] = useState(false)

    // Llamada al endpoint de borrado de dominio.
    const onDeleteDomain = () => {
        api.deleteDomain(toDelete.name).then(() =>{
            setDeleted(true)
            props.refreshZones()
            props.refreshGroups()
        })
    }

    // Se activa el modal de borrar.
    const deleteModal = (domain) => {
        setShowDeleteModal(true)
        setToDelete(domain)
    }
    
    // Si se ha borrado el dominio 
    const onDeleted = () => {
        setShowDeleteModal(false)
        setDeleted(false)
        props.close()
    }

    return (
        <Modal 
            ariaHideApp={false}
            isOpen={props.show} 
            overlayClassName="subdomain-overlay"
        >
            {showDeleteModal 
            ?
                <>
                    {deleted
                    ?
                    <ActionModal
                        closeModal={true}
                        handleClose={onDeleted}
                        icon={<CheckCircleOutlineOutlinedIcon style={{ fontSize: "100px", color:"#36C47D"}}/>}
                        text={t('deletedDomain', {name: toDelete.name})}
                    />
                    :
                    <ActionModal
                        text={t('questionDeleteDomain', {name: toDelete.name})}
                        textPrimary={t('cancel')}
                        logicPrimary={() => setShowDeleteModal(false)}
                        textOutline={t('delete')}
                        logicOutline={onDeleteDomain}
                    />
                    }
                </>
            :
            <>
                <Row className="py-3">
                    <Col className="d-flex align-items-baseline">
                        <h5>
                            {
                            props.type === "zone" 
                            ?
                            <><span className="text-primary">{t('zone')}: </span> {props.data.domain}</>
                            :
                            <><span className="text-primary">{t('group')}: </span> {props.data.name}</>
                            }
                        </h5>
                    </Col>
                    <Col md={1} className="d-flex justify-content-md-end">
                        <CancelOutlinedIcon className="d-flex-end" onClick={props.close}/>
                    </Col>
                </Row>
                <SubdomainTable
                    data={props.data}
                    deleteModal={deleteModal}
                    navigate={navigate}
                    type={props.type}
                />
            </>
            }
        </Modal>
    );
}

export default SubdomainsModal;