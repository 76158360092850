import "./App.css";
import "../src/custom.scss";
import "./css/app.css";
import Configuration from "./pages/Configuration";
import NewDomain from "./pages/NewDomain";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Error from "./pages/Error";
import ErrorContext from "./components/services/Context/ErrorContext";
import React, { useState } from "react";
import InfoConfiguration from "./pages/InfoConfiguration";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PrivateRoutes from "./components/hooks/PrivateRoutes";
import Menu from "./pages/Menu";
import HomeSafireServices from "./pages/HomeSafireServices";
import { ServicesProvider } from "./components/services/Context/ServicesContext";
import AuthBackend from "./pages/AuthBackend";
import ExternalRefreshToken from "./pages/ExternalRefreshToken";
import { LogOut } from "./pages/Logout";

/**
 * Definidas las rutas de la aplicación.
 * Existen rutas publicas y privadas, accesibles una vez el usuario haya hecho login.
 */
function App() {
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="auth/" element={<AuthBackend />} />
          <Route path="/error" element={<Error />} />
          <Route element={<PrivateRoutes />}>
              <Route element={
                <ServicesProvider>
                  <Menu/>
                </ServicesProvider>
              }>
                <Route path="/error" element={<Error />} />
                <Route path="/refreshtoken" element={<ExternalRefreshToken />} />
                <Route path="/logout" element={<LogOut />} />
                <Route
                  path="/config"
                  element={
                    <ErrorContext.Provider
                      value={{
                        errorMessage,
                        setErrorMessage,
                        showErrorDialog,
                        setShowErrorDialog,
                      }}
                    >
                      <Configuration />
                    </ErrorContext.Provider>
                  }
                />

                <Route index path="/" element={<HomeSafireServices />} />
                <Route path="/homeDNS" element={<Home />} />
                <Route path="infoConfig" element={<InfoConfiguration />} />
                <Route
                  path="infoConfig/:domainId"
                  element={<InfoConfiguration />}
                />
                <Route path="domain" element={<NewDomain domainId="" />} />
                <Route
                  path="domain/:domainId"
                  element={<NewDomain domainId="" />}
                />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Route>
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
