import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ServicesContext } from "../services/Context/ServicesContext";

/**
 * Componente donde se muestran los servicios disponibles en la vista de homeSafireServices
 *
 * @returns div
 */
const HomeServices = () => {
  const dnsServiceUrl = process.env.REACT_APP_DNS_FRONTEND_URL + "/homeDNS";

  const { t } = useTranslation("safirednstranslation");

  const { services } = useContext(ServicesContext);

  // Los diferentes servicios disponibles.
  const servicesUrls = [
    {
      enable: services.procloud,
      href: process.env.REACT_APP_PROCLOUD_FRONT_URL+"/?token="+localStorage.getItem("access_token"),
      label: "ProCloud",
      name: "procloud-service",
      src: "/img/sidenav_old/service-procloud-logo.svg",
    },
    {
      enable: services.safiredns,
      href: dnsServiceUrl,
      label: "SafireDNS",
      name: "dns-service",
      src: "/img/sidenav_old/service-dns-logo.svg",
    },
    {
      enable: services.admin,
      href: process.env.REACT_APP_ADMIN_FRONT_URL+"/?token="+localStorage.getItem("access_token"),
      label: t("administrator"),
      name: "admin-service",
      src: "/img/sidenav_old/service-admin-logo.svg",
      style: { maxWidth: "42px" },
    },
  ];

  return (
    <div className="px-4">
      <h4 className="fw-bold pt-1">{t("services")}</h4>
      <div className="d-flex flex-row mb-3 text-center">
        {servicesUrls.map((serviceUrl) => {
          return (
            serviceUrl.enable && (
              <div className="p-5" key={serviceUrl.name}>
                <a
                  className="text-decoration-none"
                  href={serviceUrl.href}
                  id={serviceUrl.name}
                >
                  <img
                    alt={serviceUrl.name}
                    src={serviceUrl.src}
                    style={serviceUrl.style}
                  />
                  <div className="fw-bold pt-1">{serviceUrl.label}</div>
                </a>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default HomeServices;
