import Tooltip from "../commonUtils/tooltip";
import {Col, FloatingLabel, Form, InputGroup, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import { PasswordInput } from "../commonUtils/PasswordInput";
import { useTranslation } from "react-i18next";

/**
 * Formulario del usuario, donde vienen con los inputs de usuario y contraseña.
 * 
 * @param {*} props 
 * @returns {Row}
 */
export default function UserInfo(props){
    const {t} = useTranslation('safirednstranslation');

    // Se rellena los dos campos para mostrar en los inputs.
    const [data,setData]=useState({
        username:"",
        password:""
    });

    useEffect(()=>{
        setData(props.state);
    },[props.state]);

    const handleChange=(e)=>{
        props.clearErrors(e.target.name);
        props.setState({...props.state,
            [e.target.name]:e.target.value
        });
    }

    return(
        <Row className="mb-3">
            <div className="mb-2 mt-4">
                <span className="fw-bold">{t('authentication')}* <Tooltip name="userData" message={t('tooltipDataUser')}/></span>
            </div>
            <Form.Group as={Col} controlId="domainUser">
            <InputGroup style={{ zIndex: 0}}>
                <FloatingLabel
                    controlId="domainUser"
                    label={t('user')}
                    className="mb-1"
                >
                    <Form.Control 
                        {...props.register("username", {required: true})} 
                        maxLength="50"
                        onChange={handleChange} 
                        placeholder="Usuario" 
                        value={data.username} 
                    />
                    {props.errors["username"] &&
                    <span className="text-primary">{t('validateRequiredUser')}</span>}
                </FloatingLabel>
                </InputGroup>
            </Form.Group>
            <PasswordInput
                data={data}
                errors={props.errors}
                handleChange={handleChange} 
                register={props.register}
            />
        </Row>
    );
}
