import React, {useRef, useState} from 'react';
import '../../css/search-box.css';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';

/**
 * Selector para filtrar datos de la tabla principal.
 * 
 * @param {*} props 
 * @returns {div}
 */
const SearchBox = (props) => {
   const {t} = useTranslation('safirednstranslation');
    const [state,setState]=useState("hide");
    const [defaultText, setDefaultText] = useState("");
    const searchInput = useRef(null)

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // Se expande o muestra solo el icono de busqueda.
    async function toogleSearch(){
        if(state==="active"){
            setDefaultText("");
            setState("hide");
            props.onChange("");
        }else{
            setState("active");
            await sleep(200);
            searchInput.current.focus();
        }

    }

    // Editamos el valor del input en caso de que el usuario introduzca letras.
    function updateSearch(event) {
        props.onChange(event.target.value);
        setDefaultText(event.target.value);
    }

    return (

    <div>
        <div className={`search-box-wrap search-${state}`}  >
            <input ref={searchInput} type="text" value={defaultText} onChange={updateSearch} className={`input-${state}`}  placeholder={t('searchAnyField')} />
            <span className="search-icon"><SearchIcon onClick={toogleSearch}/></span>
            <span className="clear-icon"> <ClearIcon onClick={toogleSearch}/></span>
        </div>
    </div>


    );
}
  
  export default SearchBox;
