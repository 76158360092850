import React, { useEffect, useState } from 'react'
import ActionModal from './ActionModal'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import * as api from "../../services/api";
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import {useTranslation } from 'react-i18next';

/**
 * Modal para el borrado de zonas y grupos.
 * 
 * @param {*} props 
 * @returns {Modal}
 */
const DeleteModal = (props) => {
    const {t} = useTranslation('safirednstranslation');
    const {addButton=false, deleteArea, handleClose, id, openTableModal, setDeleteArea, table, name, refreshZones, refreshGroups, selectedRow, state = {}, setState} = props;
    const [text, setText] = useState("")
    const [nameCurr, setNameCurr] = useState("") //Se guarda el nombre de la zona o grupo a borrar.
    let navigate = useNavigate();

    // El texto a mostrar se rellena segun sea una zona o un grupo.
    useEffect(() => {
        setText(table==='zone' ? t('questionDeleteZone', {name: name}) : t('questionDeleteGroup', {name: name})) 
    }, [table])// eslint-disable-line react-hooks/exhaustive-deps

    // Llamada a la api de borrado.
    const removeArea = () => {
        setNameCurr(name)
        if(table==='zone') {
            api.deleteZone(id).then(() => {
                setDeleteArea({delete: true, showError: false, confirmed: true})
                refreshZones();
            }).catch(() => {
                navigate("/error")
            })
        } else if(table==='group') {
            api.deleteGroup(id).then(() => {
                Object.keys(state).length > 0 && setState({...state, group: {id: "", name: ""}})
                setDeleteArea({delete: true, showError: false, confirmed: true})
                refreshGroups();
            }).catch(() => {
                navigate("/error")
            })
        }
    }

    // Abrir modal para los subdominios que tenga asociada la zona y poder borrarlos.
    const openSubdomains = () => {
        handleClose()
        openTableModal(selectedRow, table)
    }

    const closeModal = () => {
        setDeleteArea({delete: false})
        handleClose()
    }

    return (
        <Modal ariaHideApp={false} className="zone-modal" isOpen={deleteArea.delete} overlayClassName="subdomain-overlay"> 
        {
            // Se mostrara error en caso de que la zona tenga asignados dominios, no se podra borrar la zona.
            deleteArea.showError
            ?
                table==='zone'
                ?
                // Si una zona tiene dominios asociados, no se podrá eliminar.
                <ActionModal
                    closeIcon={() => setDeleteArea({delete: false, showError: false, confirmed: false})}
                    handleClose={handleClose}
                    icon={<PriorityHighIcon className="text-primary" style={{ fontSize: "100px", whiteSpace: "pre-line"}}/>}
                    logicPrimary={() => addButton && openSubdomains()}
                    text={
                        <>
                            {t('deleteZoneWithSubdomains1', {name: name})} <br/>
                            {t('deleteZoneWithSubdomains2')}
                        </>
                    }
                    textPrimary={addButton && "Ir a la lista de dominios"}
                />
                :
                // Si un grupo tiene dominios asociados, se mostrará una alerta, pero se permite el borrado del grupo.
                <ActionModal
                    handleClose={handleClose}
                    icon={<WarningAmberRoundedIcon className="text-warning" style={{ fontSize: "100px"}}/>}
                    logicOutline={removeArea}
                    logicPrimary={() => setDeleteArea({delete: false, showError: false, confirmed: false})}
                    text={
                        <>
                            {t('deleteGroupWithDomains1', {name: name})} <br/>
                            {t('deleteGroupWithDomains2')} <br/>
                            {t('deleteGroupWithDomains3')}
                        </>
                    }
                    textOutline={t('delete')}
                    textPrimary={t('cancel')}
                />
            :
                //Si ha confirmado el borrado del area
                deleteArea.confirmed 
                ? 
                    <ActionModal 
                        closeModal={true}
                        handleClose={handleClose}
                        icon={<CheckCircleOutlineOutlinedIcon className="text-success" style={{ fontSize: "100px"}}/>}
                        text={t('correctDelete', {name: nameCurr})}
                    />
                :
                    // Al no tener dominios asociados se puede borrar el area, mostramos un mensaje de confirmacion para proceder al borrado.
                    <ActionModal
                        logicPrimary={closeModal}
                        logicOutline={removeArea}
                        text={text}
                        textPrimary={t('cancel')}
                        textOutline={t('delete')}
                    />
        }
    </Modal>
  );
}

export default DeleteModal