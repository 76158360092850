import axios from "axios";
import { initAxios } from "../components/services/AxiosConfig";

//Cierre de sesión
export const LogOut = async () => {
    try {
            
        console.log("Entrandi a logout....");
        const client_id = process.env.REACT_APP_CLIENT_ID;//"spa-safire-services";
        const refresh_token= localStorage.getItem("refresh_token");
        const client_secret = process.env.REACT_APP_CLIENT_SECRET;//"y7dEyAW5bUbi6yz2Vl9g9cnGtdKyZDrN"
        let body = `client_id=${client_id}&client_secret=${client_secret}&refresh_token=${refresh_token}`;
    
        //Borramos los datos de la sesión del localstorage
        
        let url = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/visiotech/protocol/openid-connect/logout`;
    
        //Llamamos al endpoint de cierre de sesión con el Axios original que ya incluye el token
        
        return await axios.post(url, body).then(function (response){
            console.log("LOGOUT OK");
            localStorage.clear();
            initAxios(); //Iniciamos el AXIOS Config para que al no encotrar el Token realice directamente el proceso de Login
            //window.location.href = "/";
        }).catch(function (error){
            console.log(error);
        });
    } catch (error) {
        console.log("No se ha podido hacer logout");
    }

    

}