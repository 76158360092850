import React from 'react';
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

/**
 * Este componente se encarga de la logica para mostrar una alerta en caso de que se haya
 * añadido un nuevo dominio satisfactoriamente en diferentes puntos de la aplicacion.
 * 
 */
const AlertAction = (props) => {
    const { state = {}} = useLocation();
    let navigate = useNavigate();
    const {style} = props;
    const [alert, setAlert] = useState({show: false, text: ""})

    // Se estima el tiempo que sera visible la alerta.
    const visibleAlert = () => {
        setTimeout(() => {
            setAlert({ show: false, text: "" });
        }, 30000);
    };

    // Cuando el usuario quiera cerrar la alerta.
    const onClose = () => {
        setAlert({ show: false, text: "" });
    }

    // Se habilita la alerta.
    useEffect(() => {
        if (state !== null && Object.keys(state).length > 0) {
            setAlert({show: true, text: state.text})
            navigate(state, {}); 
            visibleAlert()
        }
    }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Alert dismissible onClose={() => onClose()} show={alert.show} style={style} variant="success">
            <CheckCircleOutlineOutlinedIcon /> {alert.text}
        </Alert>
    );
};

export default AlertAction;
