// Sirve para dar formato a fechas -> DD/MM/AAAA, HH:MM
export const formatDate = (date) => {
    if (date === null || date === undefined) {
        return '-'
    }
    return new Date(date).toLocaleTimeString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})
}

// Sirve para dar formato a fechas -> DD/MM/AAAA
export const formatDateDDMMAAAA = (date) => {
    return new Date(date).toLocaleDateString(['es-ES'])
}

// Obtener la fecha de hace justo un año
export const getLastYear = () => {
    const lastYear = new Date()
    lastYear.setFullYear(lastYear.getFullYear() - 1);
  
    return formatDateDDMMAAAA(lastYear);
}

//Obtener el tiempo de una fecha para poder compararlas
export const getTime = (date) => {
    var parts = date.split("/");
    var dateRes = new Date(parts[1] + "/" + parts[0] + "/" + parts[2]);
    return dateRes.getTime();
 }