import { refreshToken} from '../utils/auth'

//Backend donde se recibe el cupón del oauth
const ExternalRefreshToken = async () => {
    //Cogemos los datos que nos pasan por GET en la url
    // const query = new URLSearchParams(useLocation().search);
    // const code = query.get("code");
    try {
        const queryParameters = new URLSearchParams(window.location.search)
        let redirect = queryParameters.get("redirect")
        await refreshToken();
        const access_token = localStorage.getItem("access_token");
        // originalRequest.headers.Authorization = 'Bearer ' + access_token;
        // let url = `http://localhost:4201/?token=${access_token}`
        let url = "";
        if (redirect==="procloud"){
            url = `${process.env.REACT_APP_PROCLOUD_FRONT_URL}/?token=${access_token}`
        }else if (redirect==="admin"){
            url =  `${process.env.REACT_APP_ADMIN_FRONT_URL}/?token=${access_token}`
        }else{
            url = "/";
        }
        
        //Redireccionamos a la Web
        window.location.href = url;

    } catch {
        console.log("NO se ha podido refrescar el token")
    }
}

export default ExternalRefreshToken;
