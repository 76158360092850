import { Skeleton } from "@mui/material";
import React from "react";

/**
 * Skeleton de la pagina principal.
 * 
 * @returns {div}
 */
const HomeSkeleton = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          margin: "30px auto 40px",
          width: "90%",
        }}
      >
        <Skeleton height={40} variant="rounded" width={180} />
        <Skeleton height={40} variant="rounded" width={150} />
      </div>
      <div
        className="ag-theme-material"
        style={{
          height: 600,
          width: "90%",
          margin: "auto",
          marginBottom: "40px",
        }}
      >
        <div className="text-center">
          <Skeleton height={120} variant="text" width="100%" />
          <Skeleton height={40} variant="text" width="100%" />
          <Skeleton height={40} variant="text" width="100%" />
          <Skeleton height={40} variant="text" width="100%" />
        </div>
      </div>
    </>
  );
};

export default HomeSkeleton;
