import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { formatDate } from "../../utils/CommonMethods";
import * as api from "../../components/services/api";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

/**
 * Componente utilizado para mostrar la tabla de safireDNS en la home.
 *
 * @returns div
 */
const HomeSafireDNS = () => {
  // Se almacenan los dominios obtenidos por endpoint.
  const [domains, setDomains] = useState([]);

  // Se utiliza este estado para mostrar los skeletons.
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation("safirednstranslation");

  // Se obtienen los dominios
  const getDomains = () => {
    api.getSummary().then(result => {
      setDomains(result);
      setIsLoading(false);
    })
  };

  useEffect(() => {
    getDomains();
  }, []);

  return (
    <div className="px-4">
      <h4 className="fw-bold pt-1">SafireDNS</h4>
      <h5 className="fw-bold pt-1 text-primary">{t("addedLately")}</h5>
      <Table responsive borderless hover style={{ paddingLeft: 0 }}>
        <thead className="border-bottom border-black">
          <tr>
            <th style={{ paddingLeft: 0 }}>{t("domain")}</th>
            <th>{t("date")}</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            [...Array(4)].map((_, index) => (
              <tr key={index}>
                <td style={{ paddingLeft: 0 }}>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            ))
          ) : domains.length > 0 ? (
            domains.map((device) => (
              <tr key={device.name}>
                <td className="pt-2" style={{ paddingLeft: 0 }}>
                  {device.name}
                </td>
                <td className="pt-2">{formatDate(device.updatedAt)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="pt-4" style={{ paddingLeft: 0 }}>
                {t("noDomains3")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default HomeSafireDNS;
