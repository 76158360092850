import EditIcon from "@mui/icons-material/Edit";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from 'react-select';

const config = require('../../../config/config');
const parentZonesNames = config.domains.allowedParentZones.map(e => e.name);

/**
 * Componente para el manejo de grupos.
 * 
 * @param {*} props 
 * @returns {Select}
 */
export default function ZoneSelect(props){
    
    // Valor a mostrar para el selector de zona.
    const [value, setValue] = useState(null);
    const {openZoneModal, limitZones, parsedZones, state, setState, setZoneTemp, zoneTemp} = props;
    const {t} = useTranslation('safirednstranslation');
    
    // Se recogen las zonas para mostrarlas en el selector.
    let options = {};
    if(parsedZones.length > 0) {
        options = parsedZones.map((zone)=>{
            return {value:zone.id,label:zone.name,editable:zone.editable}
        })
        !limitZones && options.push({value:"newZone",label:t('addNewZone')})
    }

    const handleChange = (e) => {
        if (e.value === 'newZone'){
            setState({...state, zone:{id: "", name: ""}})
            setValue({value: config.domains.defaultZoneId,label: config.domains.defaultZone});
            setZoneTemp({...zoneTemp, previousId: config.domains.defaultZoneId, previousZone: config.domains.defaultZone, actualId: config.domains.defaultZoneId, actualZone: config.domains.defaultZone})
        }else{
            setValue(e);
            setState({...state, zone: {id: e.value, name: e.label}})
            setZoneTemp({...zoneTemp, previousId: zoneTemp.actualId, previousZone: zoneTemp.actualZone, actualId:e.value, actualZone: e.label})
        }
    }

    const styles = {
        indicatorSeparator: () => ({}),
        control: () => ({
            display:'flex',
            justifyContent:'space-between',
            border: '1px solid #d0d6dc',
            borderRadius: '4px',
            height: "58px"
        }),
    }

    //Editamos la caja del select cuando está una opción seleccionada para añadir un punto delante
    const SingleValue = ({children, ...props}) => (
        <components.SingleValue {...props}>.{children}</components.SingleValue>
    );

    //Editamos las opciones del desplegable para añadir el botón de edición
    const Option = (props) => {
        return (
            <components.Option {...props}>
                {props.data.value !== "newZone" ?
                <div style={{'display':'flex','justifyContent':'space-between'}}><span style={{width:'80%', textOverflow: 'ellipsis', overflow:'hidden'}}>{props.data.label}</span>{(!parentZonesNames.includes(props.data.value)) && <EditIcon role="button" onClick={(e) => {e.stopPropagation(); openZoneModal({id: props.data.value, name:  props.data.label})}}/>}</div>
                :
                <div style={{'display':'flex','justifyContent':'space-between','borderTop':'1px dotted gray','paddingTop':'3px'}} onClick={() => {openZoneModal({id: "", name:  ""})}}> {props.data.label}</div>
                }
            </components.Option>
        );
    };

    useEffect(() => {    
        setValue(
            (props.state.zone && props.state.zone.id && props.state.zone.name) ? 
            {value: props.state?.zone?.id, label: props.state?.zone?.name} :
            {value: zoneTemp.previousId, label: zoneTemp.previousZone}
        );
    },[props.state.zone]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Select 
                components={{Option, SingleValue}}
                className='selectZones'
                classNamePrefix="prefixZones"
                onChange={handleChange}
                options={options} 
                placeholder={t('selectZone')}
                styles={styles}
                value={value}
            />
            {props.errors["zoneId"] &&
            <span className="text-primary">{t('validateZone')}</span>}
        </>
    );
}
