import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React from "react";


export default function myTooltip(props){
    return (
        <OverlayTrigger
            key={`overlay-${props.name}`}
            placement="right"
            overlay={
                <Tooltip id={`tooltip-${props.name}`}>
                    {props.message}
                </Tooltip>
            }
        >
            <HelpOutlineIcon color={props.color} style={{width: "0.8rem"}}/>
        </OverlayTrigger>
    );
}