import {GetToken} from '../utils/auth'
import {useLocation} from "react-router-dom";


//Backend donde se recibe el cupón del oauth
const AuthBackend = () =>
{
    //Cogemos los datos que nos pasan por GET en la url
    const query = new URLSearchParams(useLocation().search);
    const code = query.get("code");

    return  GetToken(code);
}
export default AuthBackend;
