var configurations = {
    "proxy": {
        "cacheTimeMs": Number(process.env.REACT_APP_PROXY_CACHE_TIME_MS),
    },
    "domains": {
        "allowedParentZones": JSON.parse(process.env.REACT_APP_ALLOWED_PARENT_ZONES),
        "defaultZoneId": process.env.REACT_APP_DEFAULT_ZONE_ID,
        "defaultZone": process.env.REACT_APP_DEFAULT_ZONE,
    },
};

module.exports = configurations;