import {Col, FloatingLabel, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import "../../../css/Configuration.css";
import * as api from "../../services/api";
import { useNavigate } from "react-router-dom";
import ActionModal from "./ActionModal";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Tooltip from "../../commonUtils/tooltip";
import DeleteModal from "./DeleteModal";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { regexAlfanumeric } from "../../../utils/ConstUtils";
import { PasswordInput } from "../PasswordInput";
import { useTranslation } from "react-i18next";

const config = require('../../../config/config');
const parentZones = config.domains.allowedParentZones;
const parentZonesNames = parentZones.map(e => e.name);

/**
 * Modal para crear, actualizar y borrar una zona.
 * 
 * @param {*} props 
 * @returns {Modal}
 */
const ZoneModal = (props) => {
    const {t} = useTranslation('safirednstranslation');
    const {closeModal, deleteArea,  name, refreshZones, setDeleteArea, setState, setZoneTemp, showZoneModal, state={}, zoneId, zones, zoneTemp} = props;
    const {register, handleSubmit, clearErrors, formState: {errors}, reset} = useForm(); //Atributos para react-hook-form
    const [zone, setZone] = useState({
        domain: "",
        domains: "",
        name: "",
        parentId: config.domains.defaultZoneId, //Una zona tendra zona padre.
        parent: config.domains.defaultZoneId,
        password: "",
        user: ""
    });

    //// Sirve para confirmar acciones(guardado, actualizacion o borrado)
    // 0: mostrar formulario. | 1: confirmar accion
    const [isConfirmed, setIsConfirmed] = useState(0);

    // En caso de que las acciones no hayan ido como deberian.
    const [error, setError] = useState({showError: false, message: ""})
    
    let navigate = useNavigate();

    // Objeto para mostrar en el selector de zona padre.
    const [parsedZones, setParsedZones] = useState([]);

    // Se almacena el nombre de la zona para mostrar en el input.
    const [nameTemp, setNameTemp] = useState("");

    // Se vacían los datos del modal cuando se han terminado las acciones en modal.
    const resetZone = () => {
        setNameTemp("")
        setParsedZones(zones.map(z => ({...z})))
        setZone({
            domain: "",
            domains: "",
            name: "",
            parentId: config.domains.defaultZoneId,
            parent: config.domains.defaultZoneId,
            password: "",
            user: "",
        });
    }

    // Se desactivan los botones hasta que no se complete una accion pedida.
    const [isDisabled, setIsDisabled] = useState(false);

    // Al guardar la zona, se comprueba si se guarda o actualiza.
    const handleView = () => {
        let reformatZone = {};
        setIsDisabled(true);
        const parentZone = zones.find(z => z.name === zone.parent) //Se comprueba la zona padre de la zona.
        if (zoneId === "") {
            reformatZone = {
                domain: zone.domain.toLowerCase() + "." + parentZone.domain, //construimos el dominio para pasarselo al back con el nombre de la zona+ el dominio de la zona padre
                username: zone.user,
                password: zone.password,
                zoneId: zone.parentId // Se guarda el id de la zona padre.
            }
            api.createZone(reformatZone).then(response => {
                if (response!==undefined){
                    setIsConfirmed(1);
                    refreshZones();
                    setZone(reformatZone)
                    Object.keys(state).length !== 0 && setState({
                        ...state,
                        zoneId: response.name,
                        zone: {id: response.name, name: response.domain}
                    });
                    setZoneTemp({...zoneTemp, actualId: response.name, actualZone: response.domain})
                }else{
                    navigate("/error");
                }
            }).catch((error) => {
                setIsConfirmed(1);
                setError({showError: true, message: error.message})
            });
        } else {
            reformatZone = {
                domain: zone.domain.toLowerCase() + "." + parentZone.domain,
                username: zone.user,
                password: zone.password,
                zoneId: zone.parent // Se guarda la zona padre.
            }
            api.updateZone(zoneId, reformatZone).then(response => {
                if (response!==undefined) {
                    Object.keys(state).length !== 0 && setState({...state, zone: {id: response.name, name: response.domain}})
                    refreshZones();
                    setIsConfirmed(1);
                    setZoneTemp({...zoneTemp, actualId: response.name, actualZone: response.domain})
                }else{
                    navigate("/error");
                }
            });
        }
        reset();
    }

    // Si cambian las zonas o la zona del estado, actualizamos las zonas para mostrar en el selector
    useEffect(() => {
        setParsedZones(zones.map(z => ({...z})))
    }, [zones, state.zone])

    // Si cambia la zona del estado, comprobamos cual es la zona y la ignoramos de la lista a mostrar como zonas padre.
    useEffect(() => {
        if(Object.keys(state).length !== 0) {
            var zoneParent = ''
            zoneParent = zones.find(z => z.domain === state.zone.name)
            zoneParent !== undefined && setZone({...zone, parent: zoneParent.parent})
            var parentZones = zones.filter(z => z.name !== zone.name)
            setParsedZones(parentZones)
        }
    }, [state.zone]) // eslint-disable-line react-hooks/exhaustive-deps

    /** Al cambiar el id de la zona, es necesario comprobar si se trata de una zona nueva, 
     * una zona padre y si no lo es, recuperamos los valores de la zona en cuestion **/
    useEffect(() => {
        clearErrors();
        setParsedZones(zones.map(z => ({...z})))
        // Si es zona nueva o no tiene valor, reseteamos los valores.
        if (zoneId === "" || zoneId==="newZone" || zoneId===undefined) {
            resetZone()
        } else if(parentZonesNames.includes(zoneId)) {
            const parentZone = parentZones.find(pz => pz.name === zoneId);
            setZone({
                domain: parentZone.domain,
                name: parentZone.name,
                parent: {id: null, value: null},
            });
        } else {
            // Recuperamos la zona.
            api.getZone(zoneId).then(result => {
                const {domain, domains, name, parent, password, username } = result;
                var parentValue = "";
                if(parentZonesNames.includes(parent)) {
                    parentValue = parent
                } else {
                    if(zones.length > 0) {
                        const z = zones.find( zone => zone.name === name)
                        if(parentZonesNames.includes(z)) {
                            parentValue = z.domain.split(".")
                            parentValue.shift()
                            parentValue = parentValue.join(".")
                        } else {
                            parentValue = parent;
                        }
                    }
                }
                setParsedZones(zones.filter(z => z.domain !== domain))
                setZone({
                    domain: domain !== undefined ? domain.split(".")[0] : "",
                    domains: domains,
                    name: name,
                    password: password,
                    parent: parentValue,
                    user: username,
                });
                setNameTemp(domain !== undefined ? domain.split(".")[0] : "")
            });
        }
    }, [zoneId]) // eslint-disable-line react-hooks/exhaustive-deps

    // Se comprueba si se ha borrado la zona o directamente se cierra el modal.
    const handleClose = () => {
        setIsConfirmed(0);
        reset();
        if(deleteArea.delete && deleteArea.confirmed){
            //Es necesario comprobar la zona que se ha borrado, para no seguir utilizandola.
            let zoneFound = zoneTemp;
            if(!zones.find(z => z.name === zoneTemp.actualId)) {
                zoneFound = {previousId: config.domains.defaultZoneId, previousZone: config.domains.defaultZone, actualId: zoneFound.previousId, actualZone: zoneFound.previousZone};
            } else if(!zones.find(z => z.name === zoneTemp.previousId)) {
                zoneFound = {...zoneFound, previousId: config.domains.defaultZoneId, previousZone: config.domains.defaultZone}
            }
            Object.keys(state).length !== 0 && setState({...state, zone: {id: zoneFound.actualId, name: zoneFound.actualZone}, zoneId: ""})
            closeModal({id: zoneFound.actualId, name: zoneFound.actualZone})
            setNameTemp("")
            setZoneTemp({...zoneFound, actualId: zoneFound.actualId, actualZone: zoneFound.actualZone})
        } else {
            closeModal(state.zone)
        }
        setDeleteArea({delete: false, showError: false, confirmed: false})
        setIsDisabled(false);
    }

    const handleChange = (e) => {
        clearErrors(e.target.name);
        setZone({...zone, [e.target.name]: e.target.value});
        if(e.target.id === 'modalDomain') setNameTemp(e.target.value)
    }

    // Si la zona tiene dominios asociados, se muestra un error al intentar borrar.
    const handleDeleteZone = () => {
        var show = zone?.domains !== '' && zone.domains.length > 0
        setDeleteArea({delete: true, showError: show, confirmed: false})
    }

    const closeThis = () => {
        setZoneTemp({...zoneTemp, actualId: zoneTemp.previousId, actualZone: zoneTemp.previousZone})
        closeModal(zoneTemp.previousId)
    }

    return (
        <Modal ariaHideApp={false} isOpen={showZoneModal} className="zone-modal" overlayClassName="subdomain-overlay">
            {
            deleteArea?.delete && showZoneModal
            ?
                // Modal en caso de que se quiera borrar la zona.
                <DeleteModal
                    deleteArea={deleteArea}
                    handleClose={handleClose}
                    id={zone.name}
                    name={zone.domain}
                    refreshZones={refreshZones}
                    setDeleteArea={setDeleteArea}
                    table="zone"
                />
            :
                isConfirmed !== 1 
                ?
                <>  
                    {zoneId === "newZone" || zoneId === "" 
                    ? 
                        <h5>{t('addZone')}</h5>
                    : 
                        <h5><span className="text-primary">{t('edit')}: </span> {Object.keys(state).length > 0 ? state.zone.name : name}</h5>
                    }
                    <Form>
                        <Row className="mb-3">
                            <div className="containerDomain">
                            <Form.Group as={Col} controlId="modalDomain">
                                <FloatingLabel
                                    controlId="modalDomain"
                                    label={t('name')}
                                    className="mb-1"
                                >
                                    <Form.Control 
                                                {...register("domain", {
                                                        pattern: {value: regexAlfanumeric, message: t('validateAlfanumeric')},
                                                        required: {value: nameTemp === "", message: t('validateRequiredName')}
                                                })}
                                                autoFocus
                                                disabled={!parentZonesNames.includes(zone.name) && zone.domains.length > 0}
                                                onChange={handleChange}
                                                maxLength={50}
                                                placeholder={t('name')}
                                                value={nameTemp || ''}
                                    />
                                    {errors["domain"] &&
                                    <span className="text-primary">{errors["domain"]?.message}</span>}
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} controlId="parentZone">
                                <Form.Select
                                    {...register("parent", {required: {
                                        value: zone.parent === '',
                                        message: t('validateRequiredName')
                                    }
                                    })}
                                    className="py-3"
                                    //Se deshabilita el selector en caso de que tenga dominios asociados y no sea una zona padre.
                                    disabled={!parentZonesNames.includes(zone.name) && zone.domains.length > 0}
                                    onChange={handleChange}
                                    value={zone.parent}
                                >
                                {
                                    Object.keys(parsedZones).length !== 0 && parsedZones.map(z => (
                                        <option 
                                        key={z.name} 
                                        value={z.name}>
                                            {z.domain}
                                        </option>
                                    ))
                                }
                                </Form.Select>
                                {errors["parent"] &&
                                <span className="text-primary">{t('validateRequiredParentZone')}</span>}
                            </Form.Group>
                            </div>
                        </Row>

                        <Row className="mb-3">
                            <p className="text-primary fw-bold" >
                                {t('userData')}
                                <Tooltip name="modalDomain" message={t('tooltipDataUser')}/>
                            </p>
                            <Form.Group as={Col} controlId="domainUser">
                                <FloatingLabel
                                    controlId="domainUser"
                                    label={t('user')}
                                    className="mb-1"
                                >
                                    <Form.Control 
                                        {...register("user", { required: zone.user === ''})}
                                        onChange={handleChange}
                                        placeholder={t('user')}
                                        value={zone.user || ''} 
                                    />
                                    {errors.user &&
                                    <span className="text-primary">{t('validateRequiredUser')}</span>}
                                </FloatingLabel>
                            </Form.Group>
                            <PasswordInput
                                data={zone}
                                errors={errors}
                                handleChange={handleChange} 
                                register={register}
                            />
                        </Row>
                        <div className="text-center mt-5">
                            {zoneId !== "" && zoneId !== 'newZone' && <span role="button" className="text-primary me-md-3" onClick={handleDeleteZone}>{t('delete')}</span>}
                            <Button className="button" disabled={isDisabled} variant="outline-primary" onClick={closeThis}>{t('cancel')}</Button>
                            <Button className="button" disabled={isDisabled} variant="primary" onClick={handleSubmit(handleView)}>{t('save')}</Button>
                        </div>
                    </Form>
                </>
                :
                <>
                    {
                    error.showError
                    ?
                        // En caso de que haya un error, se muestra el error correspondiente con este modal.
                        <ActionModal
                            logicPrimary={handleClose}
                            handleClose={handleClose} 
                            icon={<PriorityHighIcon className="text-primary" style={{ fontSize: "100px"}}/>}
                            text={error.message}
                            textPrimary={t('accept')}
                        />
                    :
                        // Si se ha conseguido guardar, mostrar modal.
                        <ActionModal 
                            closeModal={true}
                            handleClose={handleClose} 
                            icon={<CheckCircleOutlineOutlinedIcon className="text-success" style={{ fontSize: "100px"}}/>}
                            text={
                                <>
                                    <b>{zone.domain}</b> {t('correctSave')}
                                </>
                            }
                        />
                    }   
                </>
            }
        </Modal>
    );
}

export default ZoneModal;
