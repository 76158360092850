import React, { useEffect, useState } from 'react'
import {Col, FloatingLabel, Form, InputGroup} from "react-bootstrap";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTranslation } from 'react-i18next';

/**
 * Componente para el uso de inputs de tipo contraseña.
 * Se trata de un input con un boton dentro que ocultara o mostrara la contraseña
 * 
 * @param {*} props 
 * @returns {Form.Group}
 */
export const PasswordInput = (props) => {
    const {t} = useTranslation('safirednstranslation');
    const {data, errors, register} = props;
    const[showPass, setShowPass]= useState(false);
    const[valuePass, setValuePass] = useState("")

    // El valor que contendra el input de la contraseña.
    useEffect(() => {
        setValuePass(data.password)
    }, [data.password])

    //Cambiar icono de mostrar u ocultar contraseña.
    const handleShowPass=()=>{
        setShowPass(!showPass)
    }

    const handleChange = (e) => {
        props.handleChange(e)
    }

    return (
        <Form.Group as={Col} controlId="domainPassword">
            
                <InputGroup style={{ zIndex: 0}}>
                    <FloatingLabel
                    controlId="domainPassword"
                    label={t('password')}
                    className="mb-1"
                    style={{flex: '1', marginBottom: '1rem'}}
                    >
                        <Form.Control
                            {...register("password", {required: data.password === ''})}
                            onChange={handleChange}
                            placeholder={t('password')}
							// El tipo cambiara dependiendo de lo que tenga el estado showPass
                            type={(showPass) ? "text" : "password"} 
                            value={valuePass || ""}
                            autoComplete="new-password"
                            maxLength="50"
                        />
                    </FloatingLabel>
                    <InputGroup.Text className="mb-1" role="button" onClick={handleShowPass}>
                        {showPass ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                    </InputGroup.Text>
                </InputGroup>
                {errors["password"] &&
                    <span className="text-primary">{t('validateRequiredPassword')}</span>}
        </Form.Group>
    )
}
