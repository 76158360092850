import axios from 'axios';
const config = require('../../config/config');

// let apiRoot="http://localhost:3002";
// let apiRoot= process.env.REACT_APP_URL_BACKEND_DNS
let apiRoot= process.env.REACT_APP_URL_BACKEND_DNS;
let apiRootAuth = process.env.REACT_APP_AUTH_URL;
let apiRootProcloud = process.env.REACT_APP_PROCLOUD_BACK_URL;

/**
 * Definimos este componente para recoger todas las peticiones API que hagamos al back.
 */

//--------------------USER------------------------------------------------

export function getUserInfo(){
    return axios.get(apiRoot + "/users")
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

export function getUserInfoAuth() {
    return axios.get( apiRootAuth + "/users/info")
    .then(function (response) {
        return response.data;
    }).catch(function (error) {
        console.log(error)
        return Promise.reject(error);
    })
}

export async function getSummary() {
    try {
        const response = await axios.get(apiRoot + "/users/summary");
        return response.data.domains;
    }catch(error) {
        console.log(error);
        return Promise.reject(error);
    }
}

export async function getSummaryProcloud() {
    try {
        const response = await axios.get(apiRootProcloud + "/users/summary");
        return response.data.data.devices;
    }catch(error) {
        console.log(error);
        return Promise.reject(error);
    }
}

export async function getDomains(search){
    //domains?page=1&length=10&orderBy=name&sort=ASC&filterName=Aitana
    try {
        const response = await axios.get(apiRoot + "/domains");
        return response.data;
    } catch (error) {
        console.log(error);
        return await Promise.reject(error);
    }
}

export function getDomain(id){
    return axios.get(apiRoot + "/domains/" + id)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

// const response = {
//     data: {
//     lastSeen:"Wed Apr 26 2023 09:24:02 GMT+0200 (hora de verano de Europa central)",
//     status: "abuse",
//     A: "8.8.8.8",
//     ban: "5"
//     }}
export function getProxyInfo(domainName){
    try {
        const response = axios.get(apiRoot + "/domains/" + domainName + "/proxyInfo");
        return response;
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }
}

/*
El dominio debe tener los siguientes campos para pasarselo en el body. El zoneID se genera en el front entiendo
{
"domain":"prueba.example.sddns2.net","username":"jorge","password":"jorge",
"zoneId":"zone-57751865-f831-437d-b63a-17d08d271914","groupId":415,
"resourceRecords":[{"type":"A","domain":"prueba.example.sddns2.net","zoneId":"zone-57751865-f831-437d-b63a-17d08d271914","value":"188.26.197.88"}]
}
 */
export function createDomain(domain){
    return axios.post(apiRoot + "/domains", transformDTO(domain))
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error)
        });
}

/*Al hacer un update se le pasa esto al body
{"username":"jorge","password":"jorge","groupId":415,"domain":"pruebaa.example.sddns2.net",
"resourceRecords":[{"type":"A","domain":"pruebaa.example.sddns2.net","zoneId":"zone-57751865-f831-437d-b63a-17d08d271914","value":"188.26.197.88"}]
}
 */

export function updateDomain(id, domain){
    return axios.put(apiRoot + "/domains/" + id, transformDTO(domain))
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error)
        });
}

export function updateLinkedStatus(id,deviceId){
    return axios.put(apiRoot + "/domains/" + id + "/" + deviceId)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

export function deleteDomain(id){
    return axios.delete(apiRoot + "/domains/" + id)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

export function checkDomainName(name) {
    return axios.get(apiRoot + "/domains/checkDomainName/"  + name)
        .then(function (response) {
            return response.data;
        }).catch(function (error) {
            console.log(error);
            return Promise.reject(error);
        });
}
//----------------------Zonas-----------------------------------------------------

export function getZones(){
    return axios.get(apiRoot + "/zones")
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

/* Recogemos las zonas y añadimos las zonas padre permitidas para el entorno donde estemos */
export function getZonesWithParents(){
    return axios.get(apiRoot + "/zones")
        .then(function (response){
            var zones = response.data
            var parents = config.domains.allowedParentZones;
            parents.map(e => zones.unshift(e));
            return zones;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

export function getZone(id){
    return axios.get(apiRoot + "/zones/" + id)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

/*La zona que pasamos debe tener los siguientes campos
{"domain":"jorge.example.sddns2.net","description":"prueba","name":"asd",
"zoneId":"zone-57751865-f831-437d-b63a-17d08d271914","username":"jorge","password":"jorge"}
 */
export function createZone(zona){
    return axios.post(apiRoot + "/zones", zona)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            //handleErrors(error,history);
            console.log(error);
            return Promise.reject(error);
        });
}


/*
En postman solo se le pasa esto en el body
{"description":"prueba","name":"asda","username":"jorge","password":"jorge"}
 */
export function updateZone(id,zone){
    return axios.put(apiRoot + "/zones/" + id,zone)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

export function deleteZone(id){
    return axios.delete(apiRoot + "/zones/" + id)
        .then(function (response){
            return response;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

//----------------------Grupos-----------------------------------------------------

export function getGroups(){
    return axios.get(apiRoot + "/groups")
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

export function getGroup(id){
    return axios.get(apiRoot + "/groups/" + id)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}
//Al crear o hacer update solo necestitamos el nombre y la descripción
export function createGroup(grupo){
    return axios.post(apiRoot + "/groups/", grupo)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

export function updateGroup(id,group){
    return axios.put(apiRoot + "/groups/" + id, group)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

export function deleteGroup(id){
    return axios.delete(apiRoot + "/groups/" + id)
        .then(function (response){
            return response.data;
        }).catch(function (error){
            console.log(error);
            return Promise.reject(error);
        });
}

// Usar mi IP
export function showMyIp() {
    return axios.post('https://ipapi.co/json/')
    .then(function(response){
        return response.data;
    }).catch(function (error) {
        console.log(error);
        return Promise.reject(error);
    })
}

const transformDTO = (dom) => {
    let res = [];
    for(const [ip, resource] of Object.entries(dom.resourcerecords)) {
        if(resource !== null && resource.value !== '') {
            res.push({value: resource.value, type: ip === 'ipv4' ? 'A' : 'AAAA'})
        }
    }

    return {
        domain: dom.domain + "." + dom.zone.name,
        zoneId: dom.zone.id,
        username: dom.username,
        password: dom.password,
        groupId: dom.group !== null && dom.group.id !== '' ? dom.group.id : null,
        resourcerecords: res
    };
    
}
