import React from 'react';
import ErrorContext from "./Context/ErrorContext";
import Modal from "react-modal";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ActionModal from '../commonUtils/modals/ActionModal';
import { useTranslation } from 'react-i18next';

/**
 * Se utiliza para mostrar en pantalla cuando el usuario haya alcanzado el numero maximo de zonas.
 * 
 * @returns {Context.Consumer}
 */
export default function ErrorDialog() {
    const {t} = useTranslation('safirednstranslation');
    return (
        <ErrorContext.Consumer>
            {context => (
                <Modal ariaHideApp={false} isOpen={context.showErrorDialog} className="group-modal" overlayClassName="subdomain-overlay">
                    <ActionModal
                        icon={<PriorityHighIcon style={{ fontSize: "80px", color:"#D33E4C"}}/>}
                        text={context.errorMessage}
                        logicPrimary={() => context.setShowErrorDialog(false)}
                        textPrimary={t('accept')}
                    />
                </Modal>
            )}
        </ErrorContext.Consumer>
    );
}