import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

/**
 * Se recorren todos los subdominios asociados, es el contenido que mostrara el modal de subdominios.
 * 
 * @param {*} props 
 * @returns {Row}
 */
const SubdomainTable = (props) => {
    const { t } = useTranslation('safirednstranslation');
    const { data, deleteModal, navigate, type } = props;
    const [domains, setDomains] = useState([])

    //Se recogen los datos dependiendo si es la tabla de grupos o de zonas
    useEffect(() => {
        if (type === "group" || type === "zone") {
            setDomains(data.domains)
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {domains !== undefined && domains.length > 0
                ?
                domains.map(domain => (
                    <Row border="dark" className="border-bottom py-2 mx-2" key={domain.id}>
                        <Col>{domain.name.split(".")[0]}</Col>
                        <Col md={2} className="d-flex justify-content-md-end">
                            <EditIcon
                                onClick={() => navigate('/domain/' + domain.name)}
                                style={{ cursor: "pointer" }}
                            />
                            <DeleteIcon
                                onClick={() => deleteModal(domain)}
                                style={{ cursor: "pointer" }}
                            />
                        </Col>
                    </Row>
                ))
                :
                <p className='text-center'> {t('noAssociatedDomains')}</p>
            }
        </>
    )
}

export default SubdomainTable