import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, {forwardRef} from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {AddBox, 
        ArrowDownward, 
        Check, 
        ChevronLeft, 
        ChevronRight, 
        Clear, 
        DeleteOutline, 
        Edit, 
        FilterList, 
        FirstPage,
        LastPage, 
        Remove, 
        SaveAlt, 
        Search, 
        ViewColumn } from '@mui/icons-material';
import Dropdown from "react-bootstrap/Dropdown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "react-bootstrap/Button";
import { Paper } from '@mui/material';
import MaterialTable from "@material-table/core";
import { useTranslation } from "react-i18next";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

/**
 * Componente para la utilizar la tabla de material table. Con ella se mostraran las zonas y los grupos.
 * 
 * @param {*} props 
 * @returns {MaterialTable}
 */

function Table(props) {
    const {t} = useTranslation('safirednstranslation');
    let columns = props.columns;
    
    // Se muestra icono de opciones, para mostrar subdominios, editar y borrar.
    columns.push({cellStyle:{textAlign:'right'},render: row =>
            <Dropdown className="dropdown-table-config">
                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                    <MoreVertIcon style={{color:'#0000008a'}}/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item disabled={((row.numberSubdomains === 0 || row.numberDomains === 0) && true)} onClick={()=>handleShowSubdomains(row)}><VisibilityIcon/> {t('subdomains')}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>handleEdit(row)}><EditIcon/> {t('edit')}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>handleDelete(row)}><DeleteIcon/> {t('remove')}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>})

    // Mostrar los subdominios
    const handleShowSubdomains = (row) => {
        props.openTableModal(row, props.table)
    }

    // Editar zona o grupo 
    const handleEdit = (row) => {
        props.handleEdit(row, props.table)
    }

    // Borrar zona o grupo
    const handleDelete = (row) => {
        props.handleDelete(row, props.table)
    }
    //Desplegable opciones personalizado
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            variant="link"
        >
            {children}
        </Button>
    ));

    return (
        <>
            <MaterialTable
                icons={tableIcons}
                columns={columns}
                data={props.formatedData}
                options={{
                    search: false,
                    paging: false,
                    headerStyle: {fontWeight: 'bold', fontSize: "medium"},
                    toolbar: false,
                    minBodyHeight: '500px'
                }}
                components={{
                    Container: props => <Paper {...props} variant="outlined" style={{padding: '25px', borderRadius: '20px'}}/>
                }}
                localization={{
                    header: {
                        actions: ""
                    }
                }}
            />
        </>
    );
}

export default Table;
