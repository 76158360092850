import {Outlet, useNavigate} from 'react-router-dom'
import {useEffect, useState} from "react";
import {AxiosInterceptors, initAxios} from "../services/AxiosConfig";
import { useTranslation } from 'react-i18next';

/**
 * Este hook se encarga de iniciar axios cuando la app este lista.
 * 
 * @returns {Hook}
 */
const PrivateRoutes = () => {
    const [appReady,setAppReady] = useState(false)
    let navigate = useNavigate();
    const {t} = useTranslation('safirednstranslation');
    useEffect(() => {
        async function startApp() {
            try {
                await initAxios(navigate)
                setAppReady(true)
            }
            catch (error){
                console.log(error)
            }
        }
        //Inicializamos los interceptors de Axios para todas las llamadas a la api
        AxiosInterceptors(navigate, t);
        //Inicializamos axios con el token y sino lo pedimos
        startApp();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // let auth = {'token':false}
    return (
        appReady && <Outlet/>
    )
}

export default PrivateRoutes
