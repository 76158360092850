import {Button, Col, FloatingLabel, Form} from "react-bootstrap";
import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import "../../../css/Configuration.css";
import * as api from "../../services/api";
import {useNavigate} from "react-router-dom";
import ActionModal from "./ActionModal";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteModal from "./DeleteModal";
import { useTranslation } from "react-i18next";
import { PriorityHighOutlined } from "@mui/icons-material";

/**
 * Modal para crear, actualizar y borrar un grupo.
 * 
 * @param {*} props 
 * @returns {Modal} 
 */
const GroupModal = (props) => {
    const {t} = useTranslation('safirednstranslation');
    const {closeModal, deleteArea, groupId, refreshGroups, setDeleteArea, setState, showGroupModal, state={}} = props;
    const {register, handleSubmit, clearErrors, formState: {errors}, reset} = useForm(); // Propiedades de react-hook-form
    
    // Sirve para confirmar acciones(guardado, actualizacion o borrado)
    // 0: mostrar formulario. | 1: confirmar accion
    const [isConfirmed, setIsConfirmed] = useState(0);

    //En caso de que las acciones no hayan ido como deberian.
    const [error, setError] = useState({ showError: false, message: "" })
    
    // Un grupo puede tener N dominios asociados, lo almacenamos para mostrar warning en caso de que quiera borrar el grupo.
    const [group,setGroup]=useState({
        domains : [], 
        id: "", 
        name: ""
    });
    let navigate = useNavigate();
    
    // Se desactivan los botones hasta que no se complete una accion pedida.
    const [isDisabled, setIsDisabled] = useState(false);

    // Cuando el usuario hace click en el boton de guardar, comprobamos si es una creacion o una actualizacion de grupo.
    const onSubmit =()=>{
        setIsDisabled(true);
        let objgroup={name:group.name};
        if (groupId===""){
            api.createGroup(objgroup).then(result=>{
                if (result!==undefined){
                    setGroup(result)
                    refreshGroups(); // Actualizamos los grupos para que aparezca en la tabla.
                    setIsConfirmed(1);
                    Object.keys(state).length !== 0 && setState({...state, group: {id: result.id, name: result.name}})
                }else{
                    navigate("/error");
                }
            }).catch(error => {
                setIsConfirmed(1);
                setError({showError: true, message: error.message })
            })
        }else {
            api.updateGroup(groupId,objgroup).then(result=>{
                if (result!==undefined){
                    refreshGroups();
                    Object.keys(state).length !== 0 && setState({...state, group: {id: result.id, name: result.name}})
                    setIsConfirmed(1);
                }else{
                    navigate("/error");
                }
            });
        }
    }

    // Si se actualiza el id del grupo, comprobamos si es un id nuevo o se trata de un grupo ya existente para actualizar.
    useEffect(()=>{
        clearErrors();
        if(groupId==="" || !groupId){
            setGroup({domains: "", id: null, name: ""});
        }else{
            api.getGroup(groupId).then(result=>{
                setGroup({domains: result.domains, id: result.id, name: result.name});
            })
        }
    },[groupId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Cerrar modal.
    const handleClose = () => {
        setIsConfirmed(0);
        clearErrors();
        reset();
        closeModal(Object.keys(state).length === 0 ? group : state.group);
        setDeleteArea({delete: false, showError: false, confirmed: false})
        setIsDisabled(false);
    }

    const handleChange =(e)=>{
        setGroup({[e.target.name]: e.target.value});
    }

    // El usuario desea eliminar el grupo, actualizamos estado deleteArea y lo mandamos al modal de borrado.
    const handleDeleteGroup = () => {
        setDeleteArea({delete: true, showError: group.domains.length !== 0, confirmed: false})
    }

    // Omitimos el enter para crear grupo.
    const handleKeyPress = (e) => {
        if(e.key === 'Enter') { e.preventDefault();}
    }

    return (
        <Modal ariaHideApp={false} isOpen={showGroupModal} className="group-modal" overlayClassName="subdomain-overlay">
            {deleteArea?.delete && showGroupModal
            ?
                <DeleteModal
                    deleteArea={deleteArea}
                    handleClose={handleClose}
                    id={groupId}
                    name={group.name}
                    refreshGroups={refreshGroups}
                    setDeleteArea={setDeleteArea}
                    setState={setState}
                    state={state}
                    table="group"
                />
            :
            isConfirmed!==1 
                ?
                <>
                    <p className="text-primary fw-bold">
                        {groupId === '' || groupId === "createGroup" ? t('addGroup') : t('editGroup')}
                         
                    </p>
                    <Form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => handleKeyPress(e)}>
                        <Col>
                            <Form.Group as={Col} controlId="groupName" >
                                <FloatingLabel
                                    controlId="groupName"
                                    label={t('groupName')}
                                    className="mb-1"
                                >
                                <Form.Control 
                                    {...register("name", {required: group.name === ''})} 
                                    autoFocus
                                    onChange={handleChange} 
                                    maxLength={50}
                                    placeholder={t('groupName')}
                                    value={group.name || ''} 
                                />
                                {errors["name"] &&
                                <span className="text-primary">{t('validateRequiredGroupName')}</span>}
                                </FloatingLabel>
                            </Form.Group>
                            <div className="text-center mt-4">
                                {groupId !== "" && <span className="text-primary me-md-3" role="button" onClick={handleDeleteGroup}>{t('delete')}</span>}
                                <Button
                                    as={Col}
                                    className="button"
                                    disabled={isDisabled}
                                    onClick={closeModal}
                                    type="button"
                                    variant="outline-primary"
                                >{t('cancel')}
                                </Button>
                                <Button
                                    className="button"
                                    disabled={isDisabled}
                                    key={"group-form"}
                                    style={{color: "white"}}
                                    type="submit"
                                    variant="primary"
                                >{t('save')}
                                </Button>
                            </div>
                        </Col>
                    </Form>
                </>
                :
                // El grupo se ha guardado.
                <>
                {error.showError ?
                <ActionModal
                    handleClose={handleClose}
                    icon={<PriorityHighOutlined className="text-primary" style={{fontSize: "100px"}}/>}
                    logicPrimary={handleClose}
                    text={error.message}
                    textPrimary={t('accept')}
                />
                :
                <ActionModal
                closeModal={true}
                handleClose={handleClose}
                icon={<CheckCircleOutlineOutlinedIcon style={{ fontSize: "100px", color:"#36C47D"}}/>}
                text={
                    <>
                            <b>{group.name}</b> {t('correctSave')}
                        </>
                    }
                    />
                }
                </>
            }
        </Modal>

    );
}

export default GroupModal;
