import { Skeleton } from "@mui/material";
import React from "react";
import { Row } from "react-bootstrap";

/**
 * Skeleton de la pagina de nuevo dominio.
 * 
 * @returns {div}
 */
const NewDomainSkeleton = () => {
    return (
            <div className="form">
                <Row className="mb-3">
                    <div className="mb-2">
                        <Skeleton height={80} variant="text" width='30%' />
                    </div>
                    <div className="containerDomain ml-5">
                        <Skeleton height={50} variant="rectangular" width='50%' />
                        &nbsp;
                        <Skeleton height={50} variant="rectangular" width='50%' />
                    </div>
                </Row>
                <Skeleton
                    className="mt-5"
                    height={50}
                    variant="rectangular"
                    width='100%'
                />
                <div className="my-5">
                    <Skeleton height={40} variant="text" width='70%' />
                    <Skeleton height={40} variant="text" width='60%' />
                    <Skeleton height={40} variant="text" width='60%' />
                </div>
                <Row className="my-5">
                    <div className="containerDomain ml-5">
                        <Skeleton height={50} variant="rectangular" width='50%' />
                        &nbsp;
                        <Skeleton height={50} variant="rectangular" width='50%' />
                    </div>
                </Row>
                <div className="containerDomain mt-5 justify-content-center">
                    <Skeleton
                        className="button"
                        height={50}
                        variant="rectangular"
                        width='20%'
                    />
                    <Skeleton
                        className="button"
                        height={50}
                        variant="rectangular"
                        width='20%'
                    />
                </div>
            </div>
    );
};

export default NewDomainSkeleton;
