import {FloatingLabel, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import { showMyIp } from "../services/api";
import {ipWaitingDevice, regexValidIpv4, regexValidIpv6} from "../../utils/ConstUtils";
import { useTranslation } from "react-i18next";

/**
 * Componente para la gestion de la ip del dominio. Existen tres formas:
 * _Esperar a conexion del dispositivo: se añade una ip por defecto y esperamos a que se sincronice con el dispositivo.
 * _Utilizar mi ip: se recoge la ip publica que tenga asignada el usuario.
 * _Añadir manualmente: el usuario introduce la ip manualmente, ya sea porque conoce la ip del dispositivo, etc.
 * 
 * @param {*} props 
 * @returns {div}
 */
export default function IpDirection(props){
    const {errors, register, setState, state} = props;
    
    // waitIp: sirve para el check de la opcion esperar en caso de exista el dominio y venga sin ips, addIp sirve para habilitar un input para meter la contraseña.
    const [ip, setIp] = useState({waitIp: false, addIp: false})

    // Comprobar si se ha marcado una opcion.
    const [checkedIp, setCheckedIp]= useState(false);

    // Comprobar si existe ipv4 y/o ipv6 para mostrar en el selector.
    const [has, setHas] = useState({v4: false, v6: false})

    // Opcion seleccionada en el formulario, por defecto, sera esperar conexion.
    const [checkValue, setCheckValue] = useState('waitIp')
    const {t} = useTranslation('safirednstranslation');

    const radios = [
        {id: "waitIp", label: t('waitDeviceConnection')},
        {id: "useIp", label: t('useMyIp')},
        {id: "addIp", label: t('addManually')}
    ]

    // Cuando se actualice resourcerecords, que es donde vienen las ips. Pueden venir ipv4 o ipv6 o ambas opciones.
    useEffect(()=>{
        if(!checkedIp && state.id !== '') {
            if((state.resourcerecords.ipv6 === null || state.resourcerecords.ipv6?.value === '') &&
                (state.resourcerecords.ipv4?.value === ipWaitingDevice || state.resourcerecords.ipv4 === null || state.resourcerecords.ipv4?.value === '')) {
                setIp({waitIp: true})
            }
            if(state.resourcerecords.ipv6 !== null && state.resourcerecords.ipv6?.value !== '') {
                setHas({...has, v6: true})
            }
            setCheckedIp(true);
        }
    },[state.resourcerecords]); // eslint-disable-line react-hooks/exhaustive-deps

    const reset = () => {
        setIp({...ip, addIp: false})
    }

    const resetIp = (ipv4, ipv6) => {
        setState({...state,
            resourcerecords: {ipv4: {value: ipv4}, ipv6: {value: ipv6}}
        });
    }

    const handleChange=(e)=>{
        if(e.target.id==='waitIp'){
            setCheckValue(e.target.id)
            reset()
            resetIp(ipWaitingDevice, '')
        } else if(e.target.id==='useIp') {
            setCheckValue(e.target.id)
            reset()
            let myIp="";
            
            // Llamada a libreria para obtener la ip publica del usuario.
            (async () => {
                myIp = await showMyIp();
                resetIp('', '')
                setState({...state, resourcerecords: {[myIp.version.toLowerCase()]: {value: myIp.ip}}});
            })();
        } else if(e.target.id==='addIp') {
            setCheckValue(e.target.id)
            setIp({...ip, [e.target.id]: true})
            resetIp('', '')
        } else {
            e.target.id = e.target.id === 'ipv4_2' ? 'ipv4' : e.target.id
            setState({...state,
                resourcerecords: {...state.resourcerecords, [e.target.id]: {value: e.target.value}}
            });
        }
    }

    return(
        <>
                <div className="mb-2 mt-4">
                    <span className="fw-bold">{t('ipAddress')}*</span>
                </div>
            {
                state.id !== '' && !ip.waitIp
                ?
                //Editar dominio
                <div>
                    <Form.Group className="mb-3" controlId="ipv4">
                        <FloatingLabel
                            controlId="ipv4"
                            label={t('ipv4Address')}
                            className="mb-1"
                        >
                            <Form.Control 
                                placeholder={"  .   .   .  "} 
                                value={state.resourcerecords.ipv4?.value || ''} 
                                {...register("ipv4", {
                                    pattern: {
                                        value: regexValidIpv4,
                                        message: t('validateWrongIp')
                                    }
                                })}
                                onChange={handleChange}
                            />
                            {errors["ipv4"] && errors["ipv4"].type === "pattern" &&
                            <span className="text-primary">{t('validateWrongIp')}</span>}
                            {errors["ipv4"] && errors["ipv4"].type === "required" &&
                            <span className="text-primary">{t('validateRequiredIp')}</span>}
                        </FloatingLabel>
                    </Form.Group>
                    <div>
                        {has.v6 &&
                            <Form.Group className="mb-3" controlId="ipv6">
                                <FloatingLabel
                                    controlId="ipv6"
                                    label={t('ipv6Address')}
                                    className="mb-1"
                                >
                                    <Form.Control 
                                        placeholder={"fe80::9a8b:aff:feb7:e649"} 
                                        value={state.resourcerecords.ipv6?.value || ''} {...register("ipv6", {
                                            pattern: {
                                                value: regexValidIpv6,
                                                message: t('validateWrongIp')
                                            }
                                        })}
                                        onChange={handleChange}
                                    />
                                    {errors["ipv6"] && errors["ipv6"].type === "pattern" &&
                                    <span className="text-primary">{t('validateWrongIp')}</span>}
                                    {errors["ipv6"] && errors["ipv6"].type === "required" &&
                                    <span className="text-primary">{t('validateRequiredIp')}</span>}
                                </FloatingLabel>
                            </Form.Group>
                        }
                    </div>
                </div>
                :  
                //Nuevo dominio
                <div className="mb-3">
                    <Form.Group controlId="ip">
                        {radios.map((radio, index) => (
                            <Form.Check
                                checked={radio.id === checkValue}
                                id={radio.id}
                                key={index}
                                label={radio.label}
                                name='ip'
                                onChange={handleChange}
                                type='radio'
                            />
                        ))} 
                    </Form.Group>
                    {   
                        ip.addIp && 
                        <Form.Group controlId="ipv4_2">
                            <Form.Control
                                placeholder={"  .   .   .  "} 
                                value={state.resourcerecords.ipv4?.value || ''} {...register("ipv4_2", {
                                    validate: value => regexValidIpv4.test(value),
                                })}
                                onChange={handleChange}
                            />
                            {errors["ipv4_2"] && errors["ipv4_2"].type === "validate" &&
                            <span className="text-primary">{t('validateWrongIp')}</span>}
                            {errors["ipv4_2"] && errors["ipv4_2"].type === "required" &&
                            <span className="text-primary">{t('validateRequiredIp')}</span>}
                        </Form.Group>
                    }
                </div> 
            }
        </>
    )
}
