import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from "@mui/icons-material/Edit";
import Select, {components} from "react-select";
import { useTranslation } from "react-i18next";

/**
 * Componente para el manejo de grupos.
 * 
 * @param {*} props 
 * @returns {div or Form}
 */
export default function GroupSelect(props) {
    
    // Valor a mostrar para el selector del grupo.
    const [value, setValue] = useState(null);

    // En caso de crear o editar un grupo, mostrar modal.
    const {openGroupModal} = props;
    const {t} = useTranslation('safirednstranslation');

    // Se recogen los grupos para mostrarlos en el selector.
    const options = props.groups.map((group) => {
        return {value: group.id, label: group.name}
    })
	// Se añade la opcion de añadir grupo.
    options.push({value: "newGroup", label: t('addNewGroup')})

    const styles = {
        indicatorSeparator: () => ({}),
        control: () => ({
            display: 'flex',
            border: '1px solid #d0d6dc',
            borderRadius: '4px',
            height: 55
        }),
    }

    const Option = (props) => {
        const groupOptions = () => {
            //Si en el desplegable está la opcion de no asociar grupo o la de nuevo grupo que no aparezca editar
                if (props.data.value === "newGroup" ){
                    return (
                        <div style={{
                            'display': 'flex',
                            'justifyContent': 'space-between',
                            'borderTop': '1px dotted gray',
                            'paddingTop': '3px'
                        }} onClick={(e) => {
                            e.stopPropagation();
                            openGroupModal({id: "", name: ""});
                        }}> {props.data.label}
                        </div>

                    )
                }else if (props.data.value === "" ){
                    return props.data.label
                }else{
                    return (
                        <div style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                            <span>{props.data.label}</span>
                            <EditIcon role="button" onClick={(e) => {
                                e.stopPropagation();
                                openGroupModal({id: props.data.value, name: props.data.label})
                            }}/>
                        </div>
                    )
                }



        }
        return (
            <components.Option {...props}>
                {//Si en el desplegable está la opcion de no asociar grupo o la de nuevo grupo que no aparezca editar
                    groupOptions()
                }
            </components.Option>
        );
    };

    const handleChange = (value) => {
        setValue(value);
        //Cuando pulsamos en la cruz de limpiar la caja devuelve null
        if (value === null){
            value = {
                value:'',
                label:''
            }
        }
        props.setState({
            ...props.state,
            group: {id: value.value, name: value.label}
        })
    };

    // En caso de que haya seleccionado otro grupo, actualizar el selector
    useEffect(() => {
        setValue((props.state.group && props.state.group.id && props.state.group.name) ? {
            value: props.state?.group?.id,
            label: props.state?.group?.name
        } : null);
    }, [props.state.group]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {// Si no hay grupos creados, mostrar texto en lugar del selector de grupos.
            !props.groups || props.groups.length === 0
                ?
                <div role="button" className="text-primary fw-bold mb-4" onClick={() => props.openGroupModal({id: "", name: ""})}>
                    <AddCircleOutlineIcon/> {t('addGroup')}</div>
                :
                <Form.Group className="mb-3" controlId="groups">
                    <div className="mt-4">
                        <span className="fw-bold">{t('group')}</span>
                    </div>
                    <Select
                        components={{Option}}
                        options={options}
                        onChange={handleChange}
                        placeholder={t('selectGroup')}
                        isClearable={true}
                        styles={styles}
                        value={value}
                    />
                </Form.Group>
            }
        </>

    );
}
