import axios from "axios";
import {GetAuthorizationCode, refreshToken} from "../../utils/auth";

/**
 * Configuracion de axios.
 */


export const  initAxios = (navigate, t) => {
    let token = localStorage.getItem("access_token");
    if (token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        // axios.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaGVjayI6dHJ1ZSwidXNlcklkIjo1NTg1LCJzZXJ2aWNlcyI6W3siaWQiOjEsIm5hbWUiOiJwcm9jbG91ZCIsInN1bW1hcnlVcmwiOiJodHRwczovL2FwaS5wcm9jbG91ZC5zYWZpcmUuc2VydmljZXMvdXNlcnMvc3VtbWFyeSIsInVzZXJfc2VydmljZXMiOnsibWV0YWRhdGEiOm51bGwsInN0YXR1cyI6ImFjdGl2ZSIsInNlcnZpY2VJZCI6MSwidXNlcklkIjo1NTg1LCJjcmVhdGVkQXQiOiIyMDIyLTA2LTE1VDEzOjQ5OjA2LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIyLTA2LTIwVDA3OjQ1OjEyLjAwMFoifX0seyJpZCI6MiwibmFtZSI6InNhZmlyZWRucyIsInN1bW1hcnlVcmwiOiJodHRwOi8vbG9jYWxob3N0OjMwMDIvdXNlcnMvc3VtbWFyeSIsInVzZXJfc2VydmljZXMiOnsibWV0YWRhdGEiOm51bGwsInN0YXR1cyI6ImFjdGl2ZSIsInNlcnZpY2VJZCI6MiwidXNlcklkIjo1NTg1LCJjcmVhdGVkQXQiOiIyMDIyLTA2LTE1VDEzOjQ5OjA2LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIyLTA2LTIwVDA3OjQ1OjEyLjAwMFoifX0seyJpZCI6MywibmFtZSI6ImFkbWluIiwic3VtbWFyeVVybCI6IiIsInVzZXJfc2VydmljZXMiOnsibWV0YWRhdGEiOm51bGwsInN0YXR1cyI6ImluYWN0aXZlIiwic2VydmljZUlkIjozLCJ1c2VySWQiOjU1ODUsImNyZWF0ZWRBdCI6IjIwMjItMDYtMTVUMTM6NDk6MDYuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjItMDYtMjBUMDc6NDU6MTIuMDAwWiJ9fV0sImlhdCI6MTY2MTQyMjk4OCwiZXhwIjoxNjYxNDI0NDg4fQ.BS7N0_129y1o9QgZVy7dpS6LLtwpv8h734_M_Iz3W2E';

    } else {
        // No hay token, pedimos el código de autorización
        GetAuthorizationCode();
    }
}

export const AxiosInterceptors = (navigate, t)  => {
    const errors = [
        {
            code: 'DOMAINS_QUOTA_REACHED',
            message: t('limitDomains'),
            status: 403
        },
        {
            code: 'BAD_ADDRESS',
            message: t('invalidIp'),
            status: 422
        },
        {
            code: 'ZONES_QUOTA_REACHED',
            message: t('limitZones'),
            status: 403
        },
        {
            code: 'DOMAIN_ALREADY_IN_USE',
            message: t('validateDomainInUse'),
            status: 403 
        },
        {
            code: 'GROUP_NAME_ALREADY_IN_USE',
            message: t('validateGroupInUse'),
            status: 403
        }
    ];
    axios.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        const originalRequest = error.config;
        if (errors.find(err=> error?.response?.status === err?.status && error?.response?.data?.code === err?.code) !== undefined) {
            const res = errors.find(err=> error.response.status === err.status && error.response.data.code === err.code)
            return Promise.reject(res); 
        }
        else if(error?.response?.status === 403 && !originalRequest._retry && error?.response?.data?.message === "No Token") GetAuthorizationCode();
        
        else if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest._retry && error?.response?.data?.message === "Expired Token") {
            originalRequest._retry = true;
            //Es un 401 de token caducado, llamamos a refreshToken()
            try{
                await refreshToken();
                const access_token = localStorage.getItem("access_token");
                originalRequest.headers.Authorization = 'Bearer ' + access_token;
                //Volvemos a llamar a axios con la información de antes
                return axios(originalRequest);
            }
            catch(error) {
                console.log(error)
            }
        }
        else if ((error?.response?.status === 401 || error?.response?.status === 403) && error?.response?.data?.message === "Expired Refresh Token") {
            //Es un 401 de refresh token caducado, volvemos al login
            GetAuthorizationCode();
            //Se devuelve una promesa que no se cierra nunca, para que nunca vuelva a la función que lo llamó y
            //se quede en el navigate, sino volvería a la función y daría error, acabando en la ventana de error
            //return new Promise(() => {});

        }
        else {
            navigate("/error")
            console.log(error)
        }

        return Promise.reject(error.response);
    });
}


