import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { formatDate } from "../../utils/CommonMethods";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
import * as api from "../../components/services/api";

/**
 *
 * Componente que muestra los ultimos cambios de ProCloud en la vista de HomeSafireServices
 *
 * @returns
 */
const HomeProcloud = () => {
  const [devices, setDevices] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation("safirednstranslation");

  // Peticion de los dispositivos de ProCloud, no se puede probar en local de momento porque no tenemos certificados.
  const getDevices = () => {
    api.getSummaryProcloud().then(result => {
      setDevices(result);
      setIsLoading(false);
    })    
  };

  useEffect(() => {
    getDevices();
  }, []);

  const getStatus = (status) => {
    let res = "";
    if (status === "ok") {
      res = (
        <div>
          <img
            className="me-2"
            alt="recovered"
            src="/img/sidenav_old/device-connected.svg"
          />
          {t("deviceRecovered")}
        </div>
      );
    } else if (status === "nok") {
      res = (
        <div>
          <img
            className="me-2"
            alt="recovered"
            src="/img/sidenav_old/device-disconnected.svg"
          />
          {t("deviceDisconnected")}
        </div>
      );
    }
    return res;
  };

  return (
    <div className="px-4">
      <h4 className="fw-bold pt-1">ProCloud</h4>
      <h5 className="fw-bold pt-1 text-primary">{t("lastestChanges")}</h5>
      <Table responsive borderless hover>
        <thead className="border-bottom">
          <tr>
            <th style={{ paddingLeft: 0 }}>{t("name")}</th>
            <th>{t("date")}</th>
            <th>{t("event")}</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            [...Array(3)].map((_, index) => (
              <tr key={index}>
                <td style={{ paddingLeft: 0 }}>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            ))
          ) : devices.length > 0 ? (
            devices.map((device) => (
              <tr key={device.id}>
                <td className="pt-3" style={{ paddingLeft: 0 }}>
                  {device.name}
                </td>
                <td className="pt-3">{formatDate(device.updatedAt)}</td>
                <td className="pt-3">{getStatus(device.status)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="pt-4" style={{ paddingLeft: 0 }}>
                {t("noDevices")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default HomeProcloud;
