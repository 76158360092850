import React, { useContext } from 'react';
import {Navbar} from "react-bootstrap";
import {Link, Outlet, useLocation} from "react-router-dom";
import { ServicesContext } from '../components/services/Context/ServicesContext';
import {LogOut} from './Logout';
import {ExitToAppOutlined} from "@mui/icons-material";
import { Button } from '@mui/material';
import { useTranslation } from "react-i18next";

/**
 * Menu mostrado en la barra lateral y parte superior. 
 * 
 * @returns {div}
 */

const Menu = () => {
    const { t } = useTranslation("safirednstranslation");

    const adminFrontUrl =process.env.REACT_APP_ADMIN_FRONT_URL+"/?token="+localStorage.getItem("access_token");
    const procloudUrl = process.env.REACT_APP_PROCLOUD_FRONT_URL+"/?token="+localStorage.getItem("access_token");
    const safireServicesURL = process.env.REACT_APP_DNS_FRONTEND_URL;
    const dnsServiceUrl = safireServicesURL + "/homeDNS"
    const {services} = useContext(ServicesContext);
    const location = useLocation();
    return (
        <>

            {/*<div id="leftSidebar" className="bg-dark text-white">
                <div>DDNS</div>
                <div>Monitorización</div>
            </div>*/}
            <div id="sidenav">
                <ul>
                    <li>
                        <a className="" id="auth-service" href={safireServicesURL}>
                            <img alt="auth-service" src="/img/sidenav_old/sidenav-safire-services.svg"/>
                        </a>
                    </li>
                    {services.procloud && 
                        <li>
                            <a className="" id="procloud-service" href={procloudUrl}>
                                <img alt="procloud-service" src="/img/sidenav_old/service-procloud-logo.svg"/>
                                <div className="title">ProCloud</div>
                            </a>
                        </li>
                    }
                    {services.safiredns && 
                        <li>
                            <a className="" id="dns-service" href={dnsServiceUrl}>
                                <img alt="dns-service" src="/img/sidenav_old/service-dns-logo.svg"/>
                                <div className="title">SafireDNS</div>
                            </a>
                        </li>
                    }
                    {services.admin && 
                        <li>
                            <a id="admin-service" href={adminFrontUrl}>
                                <img alt="admin-service" src="/img/sidenav_old/service-admin-logo.svg"/>
                                <div className="title">Admin</div>
                            </a>
                        </li>
                    }
                    <li></li>
                </ul>
            </div>
            <div className="domainContainer">
                <Navbar bg="dark" variant="dark">
                    {location.pathname==="/" ? 
                    <Link to='/'><img alt="Safire services logo" src="../../img/safire_services_logo_white.svg" style={{maxHeight:"37px", margin: '5px 50px'}}/></Link> :
                    <Link to='/homeDNS'><img alt="Safire services logo" src="../../img/logo_SafireDNS.svg" style={{maxHeight:"37px", margin: '5px 50px'}}/></Link>
                    }
                    {/* <button id="logoutButton" onClick={LogOut} > {<ExitToAppOutlined />}  Logout</button> */}
                    <Button id="logoutButton"  onClick={LogOut} startIcon={<ExitToAppOutlined /> }  >{t("logOut")}</Button>
                </Navbar>
                <Outlet />
            </div>


        </>
    );
}

export default Menu;
